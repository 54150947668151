import React from 'react';
import Swal from 'sweetalert2';
import { Link, Redirect } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faEdit, faFilter, faHashtag, faPlus, faTrashAlt, faWindowRestore } from '@fortawesome/free-solid-svg-icons';

class Listar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        loadTerminos: true,
        navigate: false,
        datosCargados: false,
        fecha_diligenciamiento: '',
        nombre_transparencia: '',
        tipo_documento_transparencia: '',
        documento_transparencia: '',
        titularidad_transparencia: '',
        compromiso_transparencia: '',
        tipo_formulario: '',
        formularios: [],
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    borrarRegistros = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar el Formulario?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                authAxios.get("formulario/delete/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                        this.ListarFormularios(1);
                    })
                    .catch(console.log);
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };
    cambiarEstado = (e) => {
        if (!this.state.loadTerminos) {
            this.setState({ loadTerminos: true })
        } else {
            this.setState({ loadTerminos: false })
        }
    }
    enviarDatos = (e) => {
        e.preventDefault();
        const { nombre_transparencia, tipo_documento_transparencia, documento_transparencia, titularidad_transparencia, compromiso_transparencia, tipo_formulario } = this.state;
        var errores = [];
        if (!nombre_transparencia) { errores.push("error_nombre_transparencia"); }
        if (!tipo_documento_transparencia) { errores.push("error_tipo_documento_transparencia"); }
        if (!documento_transparencia) { errores.push("error_documento_transparencia"); }
        if (!titularidad_transparencia) { errores.push("error_titularidad_transparencia"); }
        if (!compromiso_transparencia) { errores.push("error_compromiso_transparencia"); }
        if (!tipo_formulario) { errores.push("error_tipo_formulario"); }
        this.setState({ errores: errores });
        console.log(errores);
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form-transparencia');
        let formData = new FormData(form);
        console.log(formData);
        Swal.showLoading();
        authAxios.post("formulario/register", formData)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                Swal.fire(
                    'Formularios!',
                    datosRespuesta.data.msj,
                    'success'
                )
                Swal.close();
                // this.props.history.push("/UpTerceros/" + datosRespuesta.data.id);
                window.location.href = '/UpTerceros/' + datosRespuesta.data.id;
            })
            .catch(console.log)
        // this.setState({ navigate: true });
        // this.props.history.push("/AddProveedoresNacionalesNaturales");
        // return <Redirect to="/AddProovedoresNacionalesNaturales" push={true} />
    }
    ListarFormularios(page) {
        Swal.showLoading();
        let bname = document.getElementById('bnombre').value;
        if (bname == '') {
            bname = 0;
        }
        authAxios.get("formulario/index/" + bname + "?page=" + page)
            .then((datosRespuesta) => {
                Swal.close();
                console.log(datosRespuesta);
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                console.log(status);
                this.setState({ datosCargados: true, load: '', formularios: datosRespuesta.data.data.data, pagina: datosRespuesta.data.data.total, ultimo: datosRespuesta.data.data.last_page, actual: datosRespuesta.data.data.current_page });
                Swal.close();
            })
        .catch(console.log);
    }
    componentDidMount() {
        const date = new Date();
        const newDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        this.setState({
            fecha_diligenciamiento : newDate,
            nombre_transparencia: window.localStorage.getItem('name'),
            documento_transparencia: window.localStorage.getItem('identification'),
            tipo_documento_transparencia: window.localStorage.getItem('identification_type'),
        });
        this.ListarFormularios(1);
    }
    render() {
        const { load, loadTerminos, navigate, formularios,
                fecha_diligenciamiento, nombre_transparencia,
                tipo_documento_transparencia, documento_transparencia, titularidad_transparencia, compromiso_transparencia, tipo_formulario,
                pagina, ultimo, actual, errores } = this.state;
        // if (navigate) {
        //     return <Redirect to="/AddTerceros" push={true} />
        // }
        return (
            <>
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header">
                                <button className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#TerminosCondicionesModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Tercero</button>
                            </div>
                            <div className="card-body">
                                <h4>Lista Formularios</h4>
                                <hr />
                                <div className="row">
                                    <div className="col-xl-6">
                                        <input style={{textTransform: 'uppercase'}} type="text" id="bnombre" name="bnombre" placeholder="FORMULARIO" className="form-control form-control-sm" />
                                    </div>
                                    <div className="col-xl-1">
                                        <button id="bt" name="bt" className="btn btn-secondary btn-sm" onClick={() => this.ListarFormularios(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">
                                    <table className="table table-hover table-condensed table-sm">
                                        <thead className="thead-inverse">
                                            <tr>
                                                <th className="align-middle">NOMBRE</th>
                                                <th className="align-middle">IDENTIFICACIÓN</th>
                                                <th className="align-middle">TIPO FORMULARIO</th>
                                                <th className="align-middle">OPCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {load}
                                            {
                                                formularios.map((itemFormularios) => (
                                                    <tr key={itemFormularios.id}>
                                                        <td>{itemFormularios.name}</td>
                                                        <td>{itemFormularios.identification}</td>
                                                        {
                                                            (() => {
                                                                switch (itemFormularios.tipo_formulario) {
                                                                    case 1:
                                                                        return (<td>ACCIONISTA</td>)
                                                                    case 2:
                                                                        return (<td>CLIENTE</td>)
                                                                    case 3:
                                                                        return (<td>EMPLEADO</td>)
                                                                    case 4:
                                                                        return (<td>MIEMBRO JUNTA DIRECTIVA</td>)
                                                                    case 5:
                                                                        return (<td>PROVEEDOR EXTERIOR</td>)
                                                                    case 6:
                                                                        return (<td>PROVEEDOR NACIONAL JURÍDICO</td>)
                                                                    case 7:
                                                                        return (<td>PROVEEDOR NACIONAL NATURAL</td>)
                                                                }
                                                            })()
                                                        }
                                                        <td>
                                                            <div className="btn-group" role="group" aria-label="">
                                                                <Link
                                                                    className="btn btn-primary btn-sm"
                                                                    to={"/UpTerceros/" + itemFormularios.id}
                                                                >
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </Link>&nbsp;&nbsp;
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={() =>
                                                                        this.borrarRegistros(itemFormularios.id)
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon icon={faTrashAlt} />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarFormularios(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarFormularios(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                        {(() => {
                                            let p = [];
                                            p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarTercero(actual)}>{actual}</a></li>);
                                            return p;
                                        })()}
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarFormularios(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                        <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarFormularios(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="card-footer text-muted">
                                <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="TerminosCondicionesModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="TerminosCondicionesModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="TerminosCondicionesModalLabel">Términos y Condiciones</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form id='form-transparencia'>
                                    <div className='row'>
                                        <div className='col-xl-6'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS DILIGENCIA
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-4">
                                                                    <label htmlFor="fecha_diligenciamiento">Fecha Diligenciamiento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                        <input type='hidden' id='id_usuario' name='id_usuario' value={window.localStorage.getItem('id_usuario')} />
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="fecha_diligenciamiento" id="fecha_diligenciamiento" value={fecha_diligenciamiento}
                                                                            className={((this.verificarError("error_fecha_diligenciamiento")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Diligenciamiento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} readOnly />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Fecha Diligenciamiento</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="nombre_transparencia">Nombre Completo</label>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_transparencia" id="nombre_transparencia" value={nombre_transparencia}
                                                                        className={((this.verificarError("error_nombre_transparencia")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre Completo" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre Completo</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className='col-md-6'>
                                                                    <label htmlFor="tipo_documento_transparencia">Tipo Identificación</label>
                                                                    <select name="tipo_documento_transparencia"
                                                                        id="tipo_documento_transparencia"
                                                                        value={window.localStorage.getItem('identification_type')}
                                                                        options={window.localStorage.getItem('identification_type')}
                                                                        className={"form-select form-select-sm border border-info"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Ident.</option>
                                                                            <option value="1">CÉDULA CIUDADANIA</option>
                                                                            <option value="2">NIT</option>
                                                                            <option value="3">CÉDULA EXTRANJERÍA</option>
                                                                            <option value="4">PASAPORTE</option>
                                                                            <option value="5">TAX ID.</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Ident.</small>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="documento_transparencia">Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="number" name="documento_transparencia" id="documento_transparencia" value={documento_transparencia}
                                                                            className={((this.verificarError("error_documento_transparencia")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} min='1' />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Documento</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="titularidad_transparencia">Titularidad</label>
                                                                    <select name="titularidad_transparencia"
                                                                        id="titularidad_transparencia"
                                                                        className={((this.verificarError("error_titularidad_transparencia")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione la Titularidad</option>
                                                                            <option value="1">SI</option>
                                                                            <option value="2">NO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione la Titularidad</small>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label htmlFor="compromiso_transparencia">Compromiso</label>
                                                                    <select name="compromiso_transparencia"
                                                                        id="compromiso_transparencia"
                                                                        className={((this.verificarError("error_compromiso_transparencia")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Compromiso</option>
                                                                            <option value="1">SI</option>
                                                                            <option value="2">NO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Compromiso</small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-xl-6'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DESCRIPCIÓN FORMULARIO
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-12">
                                                                    <label htmlFor="tipo_formulario">Tipo Formulario</label>
                                                                    <select name="tipo_formulario"
                                                                        id="tipo_formulario"
                                                                        className={((this.verificarError("error_tipo_formulario")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Formulario</option>
                                                                            <option value="1">ACCIONISTA</option>
                                                                            <option value="2">CLIENTE</option>
                                                                            <option value="3">EMPLEADO</option>
                                                                            <option value="4">MIEMBRO JUNTA DIRECTIVA</option>
                                                                            <option value="5">PROVEEDOR EXTERIOR</option>
                                                                            <option value="6">PROVEEDOR NACIONAL JURÍDICO</option>
                                                                            <option value="7">PROVEEDOR NACIONAL NATURAL</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Formulario</small>
                                                                </div>
                                                            </div>
                                                            <hr style={{ marginBottom: 0 }} />
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <div className="">
                                                                        <span style={{ textTransform: 'uppercase' }}>
                                                                            <a href='#' target="_blank">Politicas y Condiciones 1</a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <div className="">
                                                                        <span style={{ textTransform: 'uppercase' }}>
                                                                            <a href='#' target="_blank">Politicas y Condiciones 2</a>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className="row g-3">
                                                                <div className="col-md-12">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" id="terminos_condiciones" onClick={this.cambiarEstado} />
                                                                        <label className="form-check-label" htmlFor="terminos_condiciones">
                                                                            Estoy de acuerdo con los Términos y Condiciones y Políticas de Seguridad
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className='row'>
                                                        <div className='col-xl-12 text-center'>
                                                            <div className="btn-group" role="group" aria-label="">
                                                                <button type="submit" className="btn btn-success btn-sm" disabled={loadTerminos ? true : false} onClick={this.enviarDatos}><FontAwesomeIcon icon={faCheck} />&nbsp;&nbsp;Aceptar</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Listar;