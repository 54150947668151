import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';

class Crear extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        nombre: '',
        descripcion: '',
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    enviarDatos = (e) => {
        e.preventDefault();
        const { nombre, descripcion } = this.state;
        var errores = [];
        if (!nombre) { errores.push("error_nombre"); }
        if (!descripcion) { errores.push("error_descripcion"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        var datosEnviar = {
            nombre: nombre,
            descripcion: descripcion
        }
        authAxios.post("roles/register", datosEnviar)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.fire(
                        'Roles!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    this.props.history.push("/MasterRoles");
                } else {
                    Swal.fire(
                        'Roles!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    render() {
        const { nombre, descripcion } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                <b>REGISTRO DE ROLES</b>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos}>
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className='row g-3'>
                                                <div className="col-md-3">
                                                    <label htmlFor="nombre">Nombre</label>
                                                    <input style={{ textTransform: 'uppercase' }} className={((this.verificarError("error_nombre")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} type="text" name="nombre" id="nombre" value={nombre} placeholder="NOMBRE" aria-describedby="helpId" onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite el Nombre</small>
                                                </div>
                                                <div className="col-md-9">
                                                    <label htmlFor="descripcion">Descripción</label>
                                                    <input style={{textTransform: 'uppercase' }} className={((this.verificarError("error_descripcion")) ? "is-invalid" : "border border-info") + ' form-control form-control-sm'} type="text" id="descripcion" name="descripcion" value={descripcion} placeholder="DESCRIPCIÓN" aria-describedby='helpId' onChange={this.cambioValor} />
                                                    <small id="helpId" className="invalid-feedback">Digite la Descripción</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="submit" className="btn btn-success btn-sm"><FontAwesomeIcon icon={faSave} />&nbsp;&nbsp;Crear Rol</button>&nbsp;&nbsp;
                                                <Link className="btn btn-danger btn-sm" to={"/MasterRoles"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Crear;