import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSecret, faCogs, faChevronRight, faChartBar, faFolder, faHandHoldingUsd, faHandshake, faNewspaper, faBookOpen, faCoins, faShieldAlt, faBars, faHeadset, faCalendar, faCity, faCreditCard, faDownload, faFilter, faSearch, faFileSignature } from '@fortawesome/free-solid-svg-icons'
import TechGateLogo from "../images/TechgateSolo.svg";
class Menu extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        dolarText: '',
        Modulos: [],
        SubModulos: [],
        names: [],
    }
    render() {
        const { Modulos, SubModulos, names, dolarText } = this.state;
        return (
            <div>
                <aside className="main-sidebar sidebar-dark-primary elevation-4">
                    {/* Brand Logo */}
                    <Link to="/home" className="brand-link">
                        <img src={TechGateLogo} className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                        <span className="brand-text font-weight-light">Portal Terceros</span>
                    </Link>
                    {/* Sidebar */}
                    <div className="sidebar">
                        {/* Sidebar user panel (optional) */}
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                            <div className="image">
                                <img src={process.env.PUBLIC_URL + '/dist/img/user2-160x160.jpg'} className="img-circle elevation-2" alt="User Image" />
                            </div>
                            <div style={{ color: "#C2C7D0" }} className="info">
                                {window.localStorage.getItem('name')}
                            </div>
                        </div>
                        {/* Sidebar Menu */}
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                <li className="nav-item menu-close">
                                    <a href="#" className="nav-link">
                                        <FontAwesomeIcon style={{ marginRight: 15 }} icon={faFileSignature} />
                                        <p>
                                            Registro de Terceros
                                            <i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="/MasterTerceros" className="nav-link">
                                                <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                                                <p>Registro Terceros</p>
                                            </Link>
                                        </li>
                                        {/* <li className="nav-item">
                                            <Link to="/MasterProveedoresNacionalesJuridicos" className="nav-link">
                                                <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                                                <p>Proveedores Nac. Jur.</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/MasterProveedoresNacionalesNaturales" className="nav-link">
                                                <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                                                <p>Proveedores Nac. Nat.</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/MasterProveedoresAccionistas" className="nav-link">
                                                <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                                                <p>Proveedores Accionistas</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/MasterProveedoresClientes" className="nav-link">
                                                <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                                                <p>Proveedores Clientes</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/MasterProveedoresJuntaDirectiva" className="nav-link">
                                                <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                                                <p>Proveedores Junta Dir.</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/MasterProveedoresEmpleados" className="nav-link">
                                                <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                                                <p>Proveedores Empleados</p>
                                            </Link>
                                        </li> */}
                                    </ul>
                                </li>
                                {
                                    window.localStorage.getItem('id_rol') === '1' ?
                                        <li className="nav-item menu-close">
                                            <a href="#" className="nav-link">
                                                <FontAwesomeIcon style={{ marginRight: 15 }} icon={faCogs} />
                                                <p>
                                                    Configuración
                                                    <i className="right fas fa-angle-left" />
                                                </p>
                                            </a>
                                            <ul className="nav nav-treeview">
                                                <li className="nav-item">
                                                    <Link to="/MasterUsuarios" className="nav-link">
                                                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                                                        <p>Usuarios</p>
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/MasterRoles" className="nav-link">
                                                        <FontAwesomeIcon style={{ marginRight: 15, marginLeft: 10, fontSize: 10 }} icon={faChevronRight} />
                                                        <p>Roles</p>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    :
                                        ''
                                }
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>
            </div>
        )
    }
}

export default Menu;