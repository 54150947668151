import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faCalendarAlt, faDollarSign, faFolderOpen, faFont, faGlobe, faHashtag, faPaperPlane, faPercentage, faPlus, faPray, faSave, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

class CrearTerceros extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        correo_electronico_oficial: '',
        autorizacion_datos_peronsales: '',
        compromiso_sagrilaft: '',
        fecha_diligenciamiento: '',
        tipo_formulario: '',
        tipo_contraparte: '',
        ciudad: '',

        accionista_ae: '',
        tipo_documento_accionista_ae: '',
        numero_documento_accionista_ae: '',
        digito_verificacion_ae: '',
        capital_accionista_ae: '',

        miembro_junta_directiva_jd: '',
        tipo_documento_miembro_junta_directiva_jd: '',
        numero_documento_miembro_junta_directiva_jd: '',
        digito_verificacion_jd: '',

        formulario: [],
        accionistas: [],
        junta_directiva: [],
        departamentos_dg: [],
        municipios_dg: [],
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    cambioValorFiles = (e) => {
        this.setState({ files: e.target.files });
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    verifyFileSize = (e) => {
        let file = e.target.files[0];
        if (file && !file.name) {
            window.alert("Please select a file");
            return false;
        }
        if (file.size > 10e6) {
            Swal.fire(
                'Cargue Archivo',
                'El tamaño del archivo sobrepasa los 10MB. Favor verificar.',
                'error'
            )
            e.target.value = null;
            return false;
        }
    }
    aceptarTerminos = (e, tipo_campo) => {
        switch (tipo_campo) {
            case 'datos_personales':
                if (e.target.value === '2') {
                    Swal.fire({
                        title: 'Está seguro de No aceptar los Términos de Autorización de Datos Peronsales?',
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'No Aceptar',
                        denyButtonText: `Cancelar`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/';
                        } else {
                            
                        }
                    })
                }
                break;
            case 'compromiso_sagrilaft':
                if (e.target.value === '2') {
                    Swal.fire({
                        title: 'Está seguro de No aceptar los Términos de Compromiso Sagrilaft?',
                        showDenyButton: true,
                        showCancelButton: false,
                        confirmButtonText: 'No Aceptar',
                        denyButtonText: `Cancelar`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = '/';
                        } else {
                            
                        }
                    })
                }
                break;
        }
    }
    borrarRegistrosPersona = (tipo_persona, id) => {
        switch (tipo_persona) {
            case 'accionista':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Accionista?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_accionista/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_accionista/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, accionistas: datosRespuesta.data.data, load: '' });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            case 'junta_directiva':
                Swal.fire({
                    title: 'Está seguro de Eliminar el Miembro de la Junta Directiva?',
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Borrar',
                    denyButtonText: `Cancelar`,
                }).then((result) => {
                    if (result.isConfirmed) {
                        authAxios.get("contraparte/delete_junta_directiva/" + id)
                            .then((datosRespuesta) => {
                                console.log(datosRespuesta);
                                Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                                authAxios.get("contraparte/index_junta_directiva/" + this.props.match.params.id)
                                    .then((datosRespuesta) => {
                                        console.log(datosRespuesta);
                                        this.setState({ datosCargados: true, junta_directiva: datosRespuesta.data.data, load: '' });
                                    })
                                    .catch(console.log);
                            })
                            .catch(console.log);
                    } else if (result.isDenied) {
                        Swal.fire('Cancelado', '', 'info')
                    }
                })
                break;
            default:
                break;
        }
    };
    enviarDatos = (e) => {
        e.preventDefault();
        var correo_electronico_oficial = document.getElementById('correo_electronico_oficial').value;
        var autorizacion_datos_peronsales = document.getElementById('correo_autorizacion_datos_peronsales').value;
        var compromiso_sagrilaft = document.getElementById('correo_compromiso_sagrilaft').value;
        var ciudad = document.getElementById('ciudad').value;
        var errores = [];
        if (!correo_electronico_oficial) { errores.push("error_correo_electronico_oficial"); }
        if (!autorizacion_datos_peronsales) { errores.push("error_autorizacion_datos_peronsales"); }
        if (!compromiso_sagrilaft) { errores.push("error_compromiso_sagrilaft"); }
        if (!ciudad) { errores.push("error_ciudad"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let form = document.getElementById('form');
        let formData = new FormData(form);
        console.log(formData);
        Swal.showLoading();
        // authAxios.post("fact_aportes/registrar", datosEnviar)
        //     .then((datosRespuesta) => {
        //         console.log(datosRespuesta)
        //         var status = datosRespuesta.data.status;
        //         if (status) {
        //             Swal.close();
        //             Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
        //             window.localStorage.clear();
        //             window.location.href = '/';
        //         }
        //         if (datosRespuesta.data.Status) {
        //             Swal.close();
        //             Swal.fire(
        //                 'Facturación Aportes Municipales!',
        //                 datosRespuesta.data.msj,
        //                 'success'
        //             )
        //             this.props.history.push("/MasterFactAportesMunicipales");
        //         } else {
        //             Swal.close();
        //             Swal.fire(
        //                 'Facturación Aportes Municipales!',
        //                 datosRespuesta.data.msj,
        //                 'error'
        //             )
        //         }
        //     })
        //     .catch(console.log)
    }
    enviarDatosAccionistas = (e) => {
        e.preventDefault();
        var accionista_ae = document.getElementById('accionista_ae').value;
        var tipo_documento_accionista_ae = document.getElementById('tipo_documento_accionista_ae').value;
        var numero_documento_accionista_ae = document.getElementById('numero_documento_accionista_ae').value;
        var capital_accionista_ae = document.getElementById('capital_accionista_ae').value;
        var errores = [];
        if (!accionista_ae) { errores.push("error_accionista_ae"); }
        if (!tipo_documento_accionista_ae) { errores.push("error_tipo_documento_accionista_ae"); }
        if (!numero_documento_accionista_ae) { errores.push("error_numero_documento_accionista_ae"); }
        if (!capital_accionista_ae) { errores.push("error_capital_accionista_ae"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formAccionista = document.getElementById('form-accionistas');
        let formDataAccionista = new FormData(formAccionista);
        console.log(formDataAccionista);
        Swal.showLoading();
        authAxios.post("contraparte/register_accionista", formDataAccionista)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Accionistas!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_accionista/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, accionistas: datosRespuesta.data.data, load: '' });
                    })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Accionistas!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    enviarDatosJuntaDirectiva = (e) => {
        e.preventDefault();
        var miembro_junta_directiva_jd = document.getElementById('miembro_junta_directiva_jd').value;
        var tipo_documento_miembro_junta_directiva_jd = document.getElementById('tipo_documento_miembro_junta_directiva_jd').value;
        var numero_documento_miembro_junta_directiva_jd = document.getElementById('numero_documento_miembro_junta_directiva_jd').value;
        var errores = [];
        if (!miembro_junta_directiva_jd) { errores.push("error_miembro_junta_directiva_jd"); }
        if (!tipo_documento_miembro_junta_directiva_jd) { errores.push("error_tipo_documento_miembro_junta_directiva_jd"); }
        if (!numero_documento_miembro_junta_directiva_jd) { errores.push("error_numero_documento_miembro_junta_directiva_jd"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        let formJuntaDirectiva = document.getElementById('form-junta_directiva');
        let formDataJuntaDirectiva = new FormData(formJuntaDirectiva);
        console.log(formDataJuntaDirectiva);
        Swal.showLoading();
        authAxios.post("contraparte/register_junta_directiva", formDataJuntaDirectiva)
            .then((datosRespuesta) => {
                console.log(datosRespuesta)
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.close();
                    Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                if (datosRespuesta.data.Status) {
                    Swal.close();
                    Swal.fire(
                        'Junta Directiva!',
                        datosRespuesta.data.msj,
                        'success'
                    )
                    authAxios.get("contraparte/index_junta_directiva/" + this.props.match.params.id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        this.setState({ datosCargados: true, junta_directiva: datosRespuesta.data.data, load: '' });
                    })
                    .catch(console.log);
                } else {
                    Swal.close();
                    Swal.fire(
                        'Junta Directiva!',
                        datosRespuesta.data.msj,
                        'error'
                    )
                }
            })
            .catch(console.log)
    }
    cargarMunicipios() {
        var departamento_procedencia_dg = document.getElementById('departamento_procedencia_dg').value;
        Swal.showLoading();
        axios.get("https://www.datos.gov.co/resource/95qx-tzs7.json?departamento=" + departamento_procedencia_dg)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, municipios_dg: datosRespuesta.data, load: '' });
                Swal.close();
            })
            .catch(console.log);
    }
    componentDidMount() {
        Swal.showLoading();
        const date = new Date();
        const newDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        this.setState({ fecha_diligenciamiento : newDate });
        authAxios.get("formulario/detail/" + this.props.match.params.id)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, formulario: datosRespuesta.data.data, load: '' });
            })
            .catch(console.log);
        authAxios.get("contraparte/index_accionista/" + this.props.match.params.id)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, accionistas: datosRespuesta.data.data, load: '' });
            })
            .catch(console.log);
        authAxios.get("contraparte/index_junta_directiva/" + this.props.match.params.id)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, junta_directiva: datosRespuesta.data.data, load: '' });
            })
            .catch(console.log);
        axios.get("https://www.datos.gov.co/resource/xd7j-ypd4.json")
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, departamentos_dg: datosRespuesta.data, load: '' });
                Swal.close();
            })
            .catch(console.log());
    }
    render() {
        const { correo_electronico_oficial, autorizacion_datos_peronsales, compromiso_sagrilaft,
                tipo_formulario, fecha_diligenciamiento,
                ciudad,
                accionista_ae, numero_documento_accionista_ae, digito_verificacion_ae, capital_accionista_ae,
                miembro_junta_directiva_jd, numero_documento_miembro_junta_directiva_jd, digito_verificacion_jd,
                formulario, accionistas, junta_directiva, departamentos_dg, municipios_dg
            } = this.state;
        return (
            <>
                <div className="content-wrapper">
                    <div className="content">
                        <div className="container-fluid">
                            <br />
                            <div className="card">
                                <div className="card-header text-center">
                                    <b>REGISTRO DE TERCEROS</b>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={this.enviarDatos} id='form-tercero'>
                                        <nav>
                                            <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button class="nav-link active" id="nav-general-tab" data-bs-toggle="tab" data-bs-target="#nav-general" type="button" role="tab" aria-controls="nav-general" aria-selected="true">General</button>
                                                <button class="nav-link" id="nav-datos_generales-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_generales" type="button" role="tab" aria-controls="nav-datos_generales" aria-selected="false">Datos Grales.</button>
                                                <button class="nav-link" id="nav-representante_legal-tab" data-bs-toggle="tab" data-bs-target="#nav-representante_legal" type="button" role="tab" aria-controls="nav-representante_legal" aria-selected="false">Rep. Legal</button>
                                                <button class="nav-link" id="nav-inf_financiera_proveedor-tab" data-bs-toggle="tab" data-bs-target="#nav-inf_financiera_proveedor" type="button" role="tab" aria-controls="nav-inf_financiera_proveedor" aria-selected="false">Inf. Financiera Prov.</button>
                                                <button class="nav-link" id="nav-accionistas_empresa-tab" data-bs-toggle="tab" data-bs-target="#nav-accionistas_empresa" type="button" role="tab" aria-controls="nav-accionistas_empresa" aria-selected="false">Accion. Empr.</button>
                                                <button class="nav-link" id="nav-miembros_junta_directiva_empresa-tab" data-bs-toggle="tab" data-bs-target="#nav-miembros_junta_directiva_empresa" type="button" role="tab" aria-controls="nav-miembros_junta_directiva_empresa" aria-selected="false">Miemb. Junta Direc.</button>
                                                <button class="nav-link" id="nav-transparencia_origen-tab" data-bs-toggle="tab" data-bs-target="#nav-transparencia_origen" type="button" role="tab" aria-controls="nav-transparencia_origen" aria-selected="false">Transp. y Orig. Fondo</button>
                                                <button class="nav-link" id="nav-funcionario_publico-tab" data-bs-toggle="tab" data-bs-target="#nav-funcionario_publico" type="button" role="tab" aria-controls="nav-funcionario_publico" aria-selected="false">Func. Públ.</button>
                                                {
                                                    formulario.tipo_formulario === 7 ?
                                                        <button class="nav-link" id="nav-declaracion_transp_pn-tab" data-bs-toggle="tab" data-bs-target="#nav-declaracion_transp_pn" type="button" role="tab" aria-controls="nav-declaracion_transp_pn" aria-selected="false">Decla. Transp. Nat.</button>
                                                    : ''
                                                }
                                                {
                                                    formulario.tipo_formulario === 6 ?
                                                        <button class="nav-link" id="nav-declaracion_transp_pj-tab" data-bs-toggle="tab" data-bs-target="#nav-declaracion_transp_pj" type="button" role="tab" aria-controls="nav-declaracion_transp_pj" aria-selected="false">Decla. Transp. Jur.</button>
                                                    : ''
                                                }
                                            </div>
                                        </nav>
                                        <div class="tab-content" id="nav-tabContent">
                                            <div class="tab-pane fade show active" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab">
                                                <div style={{ marginTop: 15 }} className="row">
                                                    <div className="col-xl-12">
                                                        <div className='row g-3'>
                                                            <div className="col-md-3">
                                                                <label htmlFor="correo_electronico_oficial">Correo Electrónico Oficial</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_oficial" id="correo_electronico_oficial" defaultValue={window.localStorage.getItem('email')}
                                                                        className={((this.verificarError("error_correo_electronico_oficial")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} readOnly />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico</small>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="autorizacion_datos_peronsales">Aut. Datos Personales</label>
                                                                <select name="autorizacion_datos_peronsales"
                                                                    id="autorizacion_datos_peronsales"
                                                                    value={formulario.autorizacion_datos_peronsales}
                                                                    options={formulario.autorizacion_datos_peronsales}
                                                                    className={((this.verificarError("error_autorizacion_datos_peronsales")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={(e) => {
                                                                        this.cambioValor(e);
                                                                        this.aceptarTerminos(e, 'datos_personales');
                                                                    }} >
                                                                        <option value="">Seleccione la Autorización</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Autorización</small>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="compromiso_sagrilaft">Compromiso Sagrilaf</label>
                                                                <select name="compromiso_sagrilaft"
                                                                    id="compromiso_sagrilaft"
                                                                    value={formulario.compromiso_sagrilaft}
                                                                    options={formulario.compromiso_sagrilaft}
                                                                    className={((this.verificarError("error_compromiso_sagrilaft")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={(e) => {
                                                                        this.cambioValor(e);
                                                                        this.aceptarTerminos(e, 'compromiso_sagrilaft');
                                                                    }} >
                                                                        <option value="">Seleccione el Compromiso</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Compromiso Sagrilaf</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="nav-datos_generales" role="tabpanel" aria-labelledby="nav-datos_generales-tab">
                                                <div style={{ marginTop: 15 }} className="row">
                                                    <div className="col-xl-12">
                                                        <div className='row g-3'>
                                                            <div className="col-md-2">
                                                                <label htmlFor="fecha_diligenciamiento">Fecha Diligenciamiento</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="fecha_diligenciamiento" id="fecha_diligenciamiento" value={fecha_diligenciamiento}
                                                                        className={((this.verificarError("error_fecha_diligenciamiento")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Diligenciamiento" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} readOnly />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Fecha Diligenciamiento</small>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="tipo_formulario">Tipo Formulario</label>
                                                                <select name="tipo_formulario"
                                                                    id="tipo_formulario"
                                                                    value={formulario.primera_vez}
                                                                    options={formulario.primera_vez}
                                                                    className={((this.verificarError("error_tipo_formulario")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor} disabled>
                                                                        <option value="">Seleccione el Tipo Formulario</option>
                                                                        <option value="1">PRIMERA VEZ</option>
                                                                        <option value="2">ACTUALIZACIÓN</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Tipo Formulario</small>
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <label htmlFor="tipo_contraparte">Tipo Contraparte</label>
                                                                <select name="tipo_contraparte"
                                                                    id="tipo_contraparte"
                                                                    value={formulario.tipo_formulario}
                                                                    options={formulario.tipo_formulario}
                                                                    className={((this.verificarError("error_tipo_contraparte")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor} disabled>
                                                                        <option value="">Seleccione el Tipo Contraparte</option>
                                                                        <option value="1">ACCIONISTA</option>
                                                                        <option value="2">CLIENTE</option>
                                                                        <option value="3">EMPLEADO</option>
                                                                        <option value="4">MIEMBRO JUNTA DIRECTIVA</option>
                                                                        <option value="5">PROVEEDOR EXTERIOR</option>
                                                                        <option value="6">PROVEEDOR NACIONAL JURÍDICO</option>
                                                                        <option value="7">PROVEEDOR NACIONAL NATURAL</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Tipo Contraparte</small>
                                                            </div>
                                                        </div>
                                                        <hr style={{ marginBottom: 0 }} />
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-4">
                                                                <label htmlFor="ciudad_dg">Ciudad</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="ciudad_dg" id="ciudad_dg" defaultValue={formulario.ciudad_dg}
                                                                    className={((this.verificarError("error_ciudad_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Ciudad" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                                <small id="helpId" className="invalid-feedback">Digite la Ciudad</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-6">
                                                                <label htmlFor="razon_social_dg">Razón Social</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="razon_social_dg" id="razon_social_dg" defaultValue={formulario.razon_social_dg}
                                                                    className={((this.verificarError("error_razon_social_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                                <small id="helpId" className="invalid-feedback">Digite la Razón Social</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-2">
                                                                <label htmlFor="tipo_persona_dg">Tipo Persona</label>
                                                                <select name="tipo_persona_dg"
                                                                    id="tipo_persona_dg"
                                                                    value={formulario.tipo_persona_dg}
                                                                    options={formulario.tipo_persona_dg}
                                                                    className={((this.verificarError("error_tipo_persona_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Tipo Persona</option>
                                                                        <option value="1">NATURAL</option>
                                                                        <option value="2">JURÍDICA</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Tipo Persona</small>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="tipo_documento_dg">Tipo Documento</label>
                                                                <select name="tipo_documento_dg"
                                                                    id="tipo_documento_dg"
                                                                    value={formulario.tipo_documento_dg}
                                                                    options={formulario.tipo_documento_dg}
                                                                    className={((this.verificarError("error_tipo_documento_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Tipo Documento</option>
                                                                        <option value="1">C.C.</option>
                                                                        <option value="2">C.E.</option>
                                                                        <option value="3">NIT</option>
                                                                        <option value="4">P.P.</option>
                                                                        <option value="5">OTRO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="numero_documento_dg">Número Documento</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="numero_documento_dg" id="numero_documento_dg" defaultValue={formulario.numero_documento_dg}
                                                                        className={((this.verificarError("error_numero_documento_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                            </div>
                                                            {
                                                                formulario.tipo_formulario !== 2 ?
                                                                    <div className="col-md-1">
                                                                        <label htmlFor="digito_verificacion_dg">DV</label>
                                                                        <div className="input-group input-group-sm">
                                                                            <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="digito_verificacion_dg" id="digito_verificacion_dg" defaultValue={formulario.digito_verificacion_dg}
                                                                                className={((this.verificarError("error_digito_verificacion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="DV" aria-describedby="helpId"
                                                                                onChange={this.cambioValor} max="37" />
                                                                        </div>
                                                                        <small id="helpId" className="invalid-feedback">Digite el DV</small>
                                                                    </div>
                                                                : ''
                                                            }
                                                        </div>
                                                        <hr style={{ marginBottom: 0 }} />
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-2">
                                                                <label htmlFor="tipo_empresa_dg">Tipo Empresa</label>
                                                                <select name="tipo_empresa_dg"
                                                                    id="tipo_empresa_dg"
                                                                    value={formulario.tipo_empresa_dg}
                                                                    options={formulario.tipo_empresa_dg}
                                                                    className={((this.verificarError("error_tipo_empresa_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Tipo Empresa</option>
                                                                        <option value="1">PRIVADA</option>
                                                                        <option value="2">MIXTA</option>
                                                                        <option value="3">PÚBLICA</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Tipo Empresa</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="fecha_constitucion_dg">Fecha Constitución</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_constitucion_dg" id="fecha_constitucion_dg" defaultValue={formulario.fecha_constitucion_dg}
                                                                        className={((this.verificarError("error_fecha_constitucion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Constitución" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Fecha Constitución</small>
                                                            </div>
                                                            {
                                                                formulario.tipo_formulario !== 2 ?
                                                                    <div className="col-md-4">
                                                                        <label htmlFor="tipo_sociedad_dg">Tipo Sociedad</label>
                                                                        <select name="tipo_sociedad_dg"
                                                                            id="tipo_sociedad_dg"
                                                                            value={formulario.tipo_sociedad_dg}
                                                                            options={formulario.tipo_sociedad_dg}
                                                                            className={((this.verificarError("error_tipo_sociedad_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                            onChange={this.cambioValor}>
                                                                                <option value="">Seleccione el Tipo Sociedad</option>
                                                                                <option value="1">SOCIEDAD POR ACCIONES SIMPLIFICADAS (S.A.S.)</option>
                                                                                <option value="2">SOCIEDAD ANÓNIMA (S.A.)</option>
                                                                                <option value="3">SOCIEDAD LIMITADA (LTDA.)</option>
                                                                                <option value="4">ENTIDAD SIN ÁNIMO DE LUCRO (E.S.A.L.)</option>
                                                                                <option value="5">SUCURSAL DE SOCIEDAD EXTRANJERA (S.S.E.)</option>
                                                                                <option value="6">SOCIEDAD COLECTIVA (S.C.)</option>
                                                                                <option value="7">SOCIEDAD EN COMANDITA POR ACCIONES (S.C.A)</option>
                                                                                <option value="8">SOCIEDAD EN COMANDITA SIMPLE (S.C.S.)</option>
                                                                                <option value="9">EMPRESA UNIPERSONAL</option>
                                                                                <option value="10">SOCIEDAD ASOCIATIVA DE TRABAJO (S.A.T.)</option>
                                                                        </select>
                                                                        <small id="helpId" className="invalid-feedback">Seleccione el Tipo Sociedad</small>
                                                                    </div>
                                                                :
                                                                    <div className="col-md-4">
                                                                        <label htmlFor="procedencia_empresa_dg">Procedencia Empresa</label>
                                                                        <select name="procedencia_empresa_dg"
                                                                            id="procedencia_empresa_dg"
                                                                            value={formulario.procedencia_empresa_dg}
                                                                            options={formulario.procedencia_empresa_dg}
                                                                            className={((this.verificarError("error_procedencia_empresa_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                            onChange={this.cambioValor}>
                                                                                <option value="">Seleccione la Procedencia Empresa</option>
                                                                                <option value="1">NACIONAL</option>
                                                                                <option value="2">EXTRANJERA</option>
                                                                        </select>
                                                                        <small id="helpId" className="invalid-feedback">Seleccione la Procedencia Empresa</small>
                                                                    </div>
                                                            }
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-2">
                                                                <label htmlFor="pais_procedencia_dg">País Procedencia</label>
                                                                <select name="pais_procedencia_dg"
                                                                    id="pais_procedencia_dg"
                                                                    value={formulario.pais_procedencia_dg}
                                                                    options={formulario.pais_procedencia_dg}
                                                                    className={((this.verificarError("error_pais_procedencia_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el País Procedencia</option>
                                                                        <option value="1">COLOMBIA</option>
                                                                        <option value="2">PAÍS 2</option>
                                                                        <option value="3">PAÍS 3</option>
                                                                        <option value="4">PAÍS 4</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el País Procedencia</small>
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <label htmlFor="departamento_procedencia_dg">Departamento</label>
                                                                <select name="departamento_procedencia_dg"
                                                                    id="departamento_procedencia_dg"
                                                                    value={formulario.departamento_procedencia_dg}
                                                                    options={formulario.departamento_procedencia_dg}
                                                                    className={((this.verificarError("error_departamento_procedencia_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={e => {
                                                                        this.cargarMunicipios(e, "funcionamiento");
                                                                        this.cambioValor(e)
                                                                    }}>
                                                                    <option value="">Seleccione el Departamento</option>
                                                                    {
                                                                        departamentos_dg.map((itemDepartamentosDG) => (
                                                                            <option style={{ textTransform: 'uppercase' }} key={itemDepartamentosDG.departamento} value={itemDepartamentosDG.departamento}>{itemDepartamentosDG.departamento}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Departamento</small>
                                                            </div>
                                                            <div className='col-md-3'>
                                                                <label htmlFor="municipio_procedencia_dg">Municipio</label>
                                                                <select name="municipio_procedencia_dg"
                                                                    id="municipio_procedencia_dg"
                                                                    value={formulario.municipio_procedencia_dg}
                                                                    options={formulario.municipio_procedencia_dg}
                                                                    className={((this.verificarError("error_municipio_procedencia_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                    <option value="">Seleccione el Municipio</option>
                                                                    {
                                                                        municipios_dg.map((itemMunicipiosDG) => (
                                                                            <option style={{ textTransform: 'uppercase' }}  key={itemMunicipiosDG.municipio} value={itemMunicipiosDG.municipio}>{itemMunicipiosDG.municipio}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Municipio</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-4">
                                                                <label htmlFor="direccion_empresa_principal_dg">Dirección Principal</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="direccion_empresa_principal_dg" id="direccion_empresa_principal_dg" defaultValue={formulario.direccion_empresa_principal_dg}
                                                                    className={((this.verificarError("error_direccion_empresa_principal_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Dirección Principal" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                                <small id="helpId" className="invalid-feedback">Digite la Dirección Principal</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="telefono_empresa_dg">Teléfono</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="telefono_empresa_dg" id="telefono_empresa_dg" defaultValue={formulario.telefono_empresa_dg}
                                                                        className={((this.verificarError("error_telefono_empresa_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Teléfono</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="extension_telefono_empresa_dg">Extensión</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="extension_telefono_empresa_dg" id="extension_telefono_empresa_dg" defaultValue={formulario.extension_telefono_empresa_dg}
                                                                        className={((this.verificarError("error_extension_telefono_empresa_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Extensión" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite la Extensión</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-2">
                                                                <label htmlFor="fax_empresa_dg">Fax</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="fax_empresa_dg" id="fax_empresa_dg" defaultValue={formulario.fax_empresa_dg}
                                                                        className={((this.verificarError("error_fax_empresa_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fax" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Fax</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="apartado_aereo_empresa_dg">Apartado Aéreo</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="apartado_aereo_empresa_dg" id="apartado_aereo_empresa_dg" defaultValue={formulario.apartado_aereo_empresa_dg}
                                                                        className={((this.verificarError("error_apartado_aereo_empresa_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Apartado Aéreo" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Apartado Aéreo</small>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="pagina_web_empresa_dg">Página Web</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faGlobe} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="pagina_web_empresa_dg" id="pagina_web_empresa_dg" defaultValue={formulario.pagina_web_empresa_dg}
                                                                        className={((this.verificarError("error_pagina_web_empresa_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Página Web" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite la Página Web</small>
                                                            </div>
                                                        </div>
                                                        <hr style={{ marginBottom: 0 }} />
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-4">
                                                                <label htmlFor="calidad_contribuyente_dg">Calidad Contribuyente</label>
                                                                <select name="calidad_contribuyente_dg"
                                                                    id="calidad_contribuyente_dg"
                                                                    value={formulario.calidad_contribuyente_dg}
                                                                    options={formulario.calidad_contribuyente_dg}
                                                                    className={((this.verificarError("error_calidad_contribuyente_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Calidad Contribuyente</option>
                                                                        <option value="1">REPONSABLE DE I.V.A.</option>
                                                                        <option value="2">NO RESPONSABLE DE I.V.A.</option>
                                                                        <option value="3">RÉGIMEN SIMPLE DE TRIBUTACIÓN</option>
                                                                        <option value="4">CONTRIBUYENTE DEL IMPUESTO DE RENTA</option>
                                                                        <option value="5">NO CONTRIBUYENTE DEL IMPUESTO DE RENTA</option>
                                                                        <option value="6">NO RESIDENTE EN COLOMBIA</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Calidad Contribuyente</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="gran_contribuyente_dg">Gran Contribuyente</label>
                                                                <select name="gran_contribuyente_dg"
                                                                    id="gran_contribuyente_dg"
                                                                    value={formulario.gran_contribuyente_dg}
                                                                    options={formulario.gran_contribuyente_dg}
                                                                    className={((this.verificarError("error_gran_contribuyente_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Gran Contr.</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Gran Contribuyente</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="zona_franca_dg">Usuario Zona Franca</label>
                                                                <select name="zona_franca_dg"
                                                                    id="zona_franca_dg"
                                                                    value={formulario.zona_franca_dg}
                                                                    options={formulario.zona_franca_dg}
                                                                    className={((this.verificarError("error_zona_franca_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione Usuario Zona Franca</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione Usu. Zona Franca</small>
                                                            </div>
                                                        </div>
                                                        <hr style={{ marginBottom: 0 }} />
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-4">
                                                                <label htmlFor="actividad_economica_dg">Actividad Económica</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="actividad_economica_dg" id="actividad_economica_dg" defaultValue={formulario.actividad_economica_dg}
                                                                    className={((this.verificarError("error_actividad_economica_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Actividad Económica" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                                <small id="helpId" className="invalid-feedback">Digite la Actividad Económica</small>
                                                            </div>
                                                            <div className="col-md-1">
                                                                <label htmlFor="ciiu_dg">CIIU</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="ciiu_dg" id="ciiu_dg" defaultValue={formulario.ciiu_dg}
                                                                        className={((this.verificarError("error_ciiu_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="CIIU" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el CIIU</small>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="regimen_renta_dg">Régimen Renta</label>
                                                                <select name="regimen_renta_dg"
                                                                    id="regimen_renta_dg"
                                                                    value={formulario.regimen_renta_dg}
                                                                    options={formulario.regimen_renta_dg}
                                                                    className={((this.verificarError("error_regimen_renta_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Régimen Renta</option>
                                                                        <option value="1">DECLARANTE DE RENTA</option>
                                                                        <option value="2">NO DECLARANTE DE RENTA</option>
                                                                        <option value="3">REPONSABLE DE I.V.A.</option>
                                                                        <option value="4">NO RESPONSABLE DE I.V.A.</option>
                                                                        <option value="5">GRAN CONTRIBUYENTE</option>
                                                                        <option value="6">AUTORETENEDOR</option>
                                                                        <option value="7">RÉGIMEN ESPECIAL (E.S.A.L)</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Régimen Renta</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="autoretenedor_dg">Autoretenedor</label>
                                                                <select name="autoretenedor_dg"
                                                                    id="autoretenedor_dg"
                                                                    value={formulario.autoretenedor_dg}
                                                                    options={formulario.autoretenedor_dg}
                                                                    className={((this.verificarError("error_autoretenedor_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione Autoretenedor</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione Autoretenedor</small>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="no_resolucion_dg">Resolución</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="no_resolucion_dg" id="no_resolucion_dg" defaultValue={formulario.no_resolucion_dg}
                                                                    className={((this.verificarError("error_no_resolucion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Resolución" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                                <small id="helpId" className="invalid-feedback">Digite la Resolución</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="fecha_resolucion_dg">Fecha</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_resolucion_dg" id="fecha_resolucion_dg" defaultValue={formulario.fecha_resolucion_dg}
                                                                        className={((this.verificarError("error_fecha_resolucion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Fecha</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-4">
                                                                <label htmlFor="concepto_retencion_dg">Concepto</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="concepto_retencion_dg" id="concepto_retencion_dg" defaultValue={formulario.concepto_retencion_dg}
                                                                    className={((this.verificarError("error_concepto_retencion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Concepto" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Concepto</small>
                                                            </div>
                                                            <div className="col-md-1">
                                                                <label htmlFor="porcentaje_retencion_dg">Porcentaje</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faPercentage} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="number" name="porcentaje_retencion_dg" id="porcentaje_retencion_dg" defaultValue={formulario.porcentaje_retencion_dg}
                                                                        className={((this.verificarError("error_porcentaje_retencion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="%" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Porcentaje</small>
                                                            </div>
                                                        </div>
                                                        <hr style={{ marginBottom: 0 }} />
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-4">
                                                                <label htmlFor="condicion_retencion_dg">Condición</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="condicion_retencion_dg" id="condicion_retencion_dg" defaultValue={formulario.condicion_retencion_dg}
                                                                    className={((this.verificarError("error_condicion_retencion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Condición" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                                <small id="helpId" className="invalid-feedback">Digite la Condición</small>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="tamano_empresa_dg">Tamaño Empresa</label>
                                                                <select name="tamano_empresa_dg"
                                                                    id="tamano_empresa_dg"
                                                                    value={formulario.tamano_empresa_dg}
                                                                    options={formulario.tamano_empresa_dg}
                                                                    className={((this.verificarError("error_tamano_empresa_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Tamaño Empresa</option>
                                                                        <option value="1">TAMAÑO 1</option>
                                                                        <option value="2">TAMAÑO 2</option>
                                                                        <option value="3">TAMAÑO 3</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Tamaño Empresa</small>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="factura_electronica_retencion_dg">Factura Electrónica</label>
                                                                <select name="factura_electronica_retencion_dg"
                                                                    id="factura_electronica_retencion_dg"
                                                                    value={formulario.factura_electronica_retencion_dg}
                                                                    options={formulario.factura_electronica_retencion_dg}
                                                                    className={((this.verificarError("error_factura_electronica_retencion_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione Factura Electrónica</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione Factura Electrónica</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className="row g-3">
                                                            <div className="col-md-4">
                                                                <label htmlFor="correo_electronico_envio_retencion_dg">Correo Electrónico Envío</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_envio_retencion_dg" id="correo_electronico_envio_retencion_dg" defaultValue={formulario.correo_electronico_envio_retencion_dg}
                                                                        className={((this.verificarError("error_correo_electronico_envio_retencion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico Envío" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico Envío</small>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="correo_electronico_rechazo_retencion_dg">Correo Electrónico Rechazo</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_rechazo_retencion_dg" id="correo_electronico_rechazo_retencion_dg" defaultValue={formulario.correo_electronico_rechazo_retencion_dg}
                                                                        className={((this.verificarError("error_correo_electronico_rechazo_retencion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico Rechazo" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico Rechazo</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className="row g-3">
                                                            <div className="col-md-4">
                                                                <label htmlFor="contacto_contabilidad_dg">Contacto Contabilidad</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="contacto_contabilidad_dg" id="contacto_contabilidad_dg" defaultValue={formulario.contacto_contabilidad_dg}
                                                                    className={((this.verificarError("error_contacto_contabilidad_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Contacto Contabilidad" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Contacto Contabilidad</small>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="correo_electronico_contacto_contabilidad_dg">Correo Electrónico Contacto Cont.</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_contacto_contabilidad_dg" id="correo_electronico_contacto_contabilidad_dg" defaultValue={formulario.correo_electronico_contacto_contabilidad_dg}
                                                                        className={((this.verificarError("error_correo_electronico_contacto_contabilidad_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico Contacto Cont." aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico Contacto Cont.</small>
                                                            </div>
                                                        </div>
                                                        <hr style={{ marginBottom: 0 }} />
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-4'>
                                                                <label htmlFor="tipo_proveedor_dg">Tipo Proveedor</label>
                                                                <select name="tipo_proveedor_dg"
                                                                    id="tipo_proveedor_dg"
                                                                    value={formulario.tipo_proveedor_dg}
                                                                    options={formulario.tipo_proveedor_dg}
                                                                    className={((this.verificarError("error_tipo_proveedor_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Proveedor</option>
                                                                        <option value="1">BIENES</option>
                                                                        <option value="2">SERVICIOS</option>
                                                                        <option value="3">BIENES Y SERVICIOS</option>
                                                                        <option value="4">OTROS</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Bien - Servicio</small>
                                                            </div>
                                                            <div className='col-md-4'>
                                                                <label htmlFor="bien_servicio_dg">Bien - Servicio</label>
                                                                <select name="bien_servicio_dg"
                                                                    id="bien_servicio_dg"
                                                                    value={formulario.bien_servicio_dg}
                                                                    options={formulario.bien_servicio_dg}
                                                                    className={((this.verificarError("error_bien_servicio_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Bien - Servicio</option>
                                                                        <option value="1">BIENES SERVICIO 1</option>
                                                                        <option value="2">BIENES SERVICIO 2</option>
                                                                        <option value="3">BIENES SERVICIO 3</option>
                                                                        <option value="4">BIENES SERVICIO 4</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Bien - Servicio</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-4">
                                                                <label htmlFor="terminos_negociacion_dg">Términos Negociación</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="terminos_negociacion_dg" id="terminos_negociacion_dg" defaultValue={formulario.terminos_negociacion_dg}
                                                                    className={((this.verificarError("error_terminos_negociacion_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Términos Negociación" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} />
                                                                <small id="helpId" className="invalid-feedback">Digite los Términos Negociación</small>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="plazo_pago_facturas_dg">Plazo Pago Factura</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="plazo_pago_facturas_dg" id="plazo_pago_facturas_dg" defaultValue={formulario.plazo_pago_facturas_dg}
                                                                    className={((this.verificarError("error_plazo_pago_facturas_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Plazo Pago Factura" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} />
                                                                <small id="helpId" className="invalid-feedback">Digite el Plazo Pago Factura</small>
                                                            </div>
                                                        </div>
                                                        <hr style={{ marginBottom: 0 }} />
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-4">
                                                                <label htmlFor="contacto_proveedor_dg">Contacto Proveedor</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="contacto_proveedor_dg" id="contacto_proveedor_dg" defaultValue={formulario.contacto_proveedor_dg}
                                                                    className={((this.verificarError("error_contacto_proveedor_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Contacto Proveedor" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} />
                                                                <small id="helpId" className="invalid-feedback">Digite el Contacto Proveedor</small>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="correo_electronico_contacto_proveedor_dg">Correo Electrónico Contacto Prov.</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_contacto_proveedor_dg" id="correo_electronico_contacto_proveedor_dg" defaultValue={formulario.correo_electronico_contacto_proveedor_dg}
                                                                        className={((this.verificarError("error_correo_electronico_contacto_proveedor_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico Contacto Prov." aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico Contacto Prov.</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-2">
                                                                <label htmlFor="telefono_contacto_proveedor_dg">Teléfono Contacto Prov.</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="telefono_contacto_proveedor_dg" id="telefono_contacto_proveedor_dg" defaultValue={formulario.telefono_contacto_proveedor_dg}
                                                                        className={((this.verificarError("error_telefono_contacto_proveedor_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono Contacto Prov." aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Teléfono Contacto Prov.</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="extension_telefono_contacto_proveedor_dg">Extensión Contacto Prov.</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="extension_telefono_contacto_proveedor_dg" id="extension_telefono_contacto_proveedor_dg" defaultValue={formulario.extension_telefono_contacto_proveedor_dg}
                                                                        className={((this.verificarError("error_extension_telefono_contacto_proveedor_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Extensión Contacto Prov." aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite la Extensión Contacto Prov.</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="celular1_contacto_proveedor_dg">Celular 1 Contacto Prov.</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="celular1_contacto_proveedor_dg" id="celular1_contacto_proveedor_dg" defaultValue={formulario.celular1_contacto_proveedor_dg}
                                                                        className={((this.verificarError("error_celular1_contacto_proveedor_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Celular 1 Contacto Prov." aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Celular 1 Contacto Prov.</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="celular2_contacto_proveedor_dg">Celular 2 Contacto Prov.</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="celular2_contacto_proveedor_dg" id="celular2_contacto_proveedor_dg" defaultValue={formulario.celular2_contacto_proveedor_dg}
                                                                        className={((this.verificarError("error_celular2_contacto_proveedor_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Celular 2 Contacto Prov." aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Celular 2 Contacto Prov.</small>
                                                            </div>
                                                        </div>
                                                        <hr style={{ marginBottom: 0 }} />
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-4'>
                                                                <label htmlFor="banco1_dg">Banco</label>
                                                                <select name="banco1_dg"
                                                                    id="banco1_dg"
                                                                    value={formulario.banco1_dg}
                                                                    options={formulario.banco1_dg}
                                                                    className={((this.verificarError("error_banco1_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Banco</option>
                                                                        <option value="1">BANCO DE BOGOTÁ</option>
                                                                        <option value="2">DAVIVIENDA</option>
                                                                        <option value="3">BANCOLOMBIA</option>
                                                                        <option value="4">BANCO POPULAR</option>
                                                                        <option value="5">BANCO AV VILLAS</option>
                                                                        <option value="6">BANCO DE OCCIDENTE</option>
                                                                        <option value="6">BANCO CAJA SOCIAL</option>
                                                                        <option value="7">BANCO AGRARIO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Banco</small>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="tipo_cuenta_banco1_dg">Tipo Cuenta</label>
                                                                <select name="tipo_cuenta_banco1_dg"
                                                                    id="tipo_cuenta_banco1_dg"
                                                                    value={formulario.tipo_cuenta_banco1_dg}
                                                                    options={formulario.tipo_cuenta_banco1_dg}
                                                                    className={((this.verificarError("error_tipo_cuenta_banco1_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Tipo Cuenta</option>
                                                                        <option value="1">AHORROS</option>
                                                                        <option value="2">CORRIENTE</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Tipo Cuenta</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="numero_cuenta_banco1_dg">No. Cuenta</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="number" name="numero_cuenta_banco1_dg" id="numero_cuenta_banco1_dg" defaultValue={formulario.numero_cuenta_banco1_dg}
                                                                        className={((this.verificarError("error_numero_cuenta_banco1_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="No. Cuenta" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el No. Cuenta</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-4">
                                                                <label htmlFor="contacto_beneficiario1_dg">Contacto Beneficiario 1</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="contacto_beneficiario1_dg" id="contacto_beneficiario1_dg" defaultValue={formulario.contacto_beneficiario1_dg}
                                                                    className={((this.verificarError("error_contacto_beneficiario1_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Contacto Beneficiario 1" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} />
                                                                <small id="helpId" className="invalid-feedback">Digite el Contacto Beneficiario 1</small>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="tipo_documento_beneficiario1_dg">Tipo Documento</label>
                                                                <select name="tipo_documento_beneficiario1_dg"
                                                                    id="tipo_documento_beneficiario1_dg"
                                                                    value={formulario.tipo_documento_beneficiario1_dg}
                                                                    options={formulario.tipo_documento_beneficiario1_dg}
                                                                    className={((this.verificarError("error_tipo_documento_beneficiario1_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Tipo Documento</option>
                                                                        <option value="1">C.C.</option>
                                                                        <option value="2">C.E.</option>
                                                                        <option value="3">NIT</option>
                                                                        <option value="4">P.P.</option>
                                                                        <option value="5">OTRO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="numero_documento_beneficiario1_dg">Número Documento</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="numero_documento_beneficiario1_dg" id="numero_documento_beneficiario1_dg" defaultValue={formulario.numero_documento_beneficiario1_dg}
                                                                        className={((this.verificarError("error_numero_documento_beneficiario1_dg")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                            </div>
                                                        </div>
                                                        <hr style={{ marginBottom: 0 }} />
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="poliza_garantia_dg">Polizas o Garantías</label>
                                                                <select name="poliza_garantia_dg"
                                                                    id="poliza_garantia_dg"
                                                                    value={formulario.poliza_garantia_dg}
                                                                    options={formulario.poliza_garantia_dg}
                                                                    className={((this.verificarError("error_poliza_garantia_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione Polizas o Garantías</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione Polizas o Garantías</small>
                                                            </div>
                                                        </div>
                                                        <hr style={{ marginBottom: 0 }} />
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-4'>
                                                                <label htmlFor="files_datos_adjuntos_dg">Datos Adjuntos</label>
                                                                <div className='input-group input-group-sm mb-3'>
                                                                    <label className="input-group-text label_files" htmlFor="files_datos_adjuntos_dg"><FontAwesomeIcon icon={faFolderOpen} />&nbsp;&nbsp;Seleccionar Archivo(s) </label>
                                                                    <input
                                                                        className={((this.verificarError("error_files_datos_adjuntos_dg")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        type="file" id="files_datos_adjuntos_dg" multiple accept="application/pdf"
                                                                        onChange={(e) => {
                                                                            this.cambioValorFiles(e);
                                                                            this.verifyFileSize(e);
                                                                        }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="nav-representante_legal" role="tabpanel" aria-labelledby="nav-representante_legal-tab">
                                                <div style={{ marginTop: 15 }} className="row">
                                                    <div className="col-xl-12">
                                                        <div className='row g-3'>
                                                            <div className="col-md-4">
                                                                <label htmlFor="rep_legal_rp">Representante Legal</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="rep_legal_rp" id="rep_legal_rp" defaultValue={formulario.rep_legal_rp}
                                                                    className={((this.verificarError("error_rep_legal_rp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Representante Legal" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} />
                                                                <small id="helpId" className="invalid-feedback">Digite el Representante Legal</small>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="socio_rep_legal_rp">Socio</label>
                                                                <select name="socio_rep_legal_rp"
                                                                    id="socio_rep_legal_rp"
                                                                    value={formulario.socio_rep_legal_rp}
                                                                    options={formulario.socio_rep_legal_rp}
                                                                    className={((this.verificarError("error_socio_rep_legal_rp")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione es Socio</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione es Socio</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="tipo_documento_rep_legal_rp">Tipo Documento</label>
                                                                <select name="tipo_documento_rep_legal_rp"
                                                                    id="tipo_documento_rep_legal_rp"
                                                                    value={formulario.tipo_documento_rep_legal_rp}
                                                                    options={formulario.tipo_documento_rep_legal_rp}
                                                                    className={((this.verificarError("error_tipo_documento_rep_legal_rp")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Tipo Documento</option>
                                                                        <option value="1">C.C.</option>
                                                                        <option value="2">C.E.</option>
                                                                        <option value="3">NIT</option>
                                                                        <option value="4">P.P.</option>
                                                                        <option value="5">OTRO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="numero_documento_rep_legal_rp">Número Documento</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="numero_documento_rep_legal_rp" id="numero_documento_rep_legal_rp" defaultValue={formulario.numero_documento_rep_legal_rp}
                                                                        className={((this.verificarError("error_numero_documento_rep_legal_rp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="expedicion_documento_rep_legal_rp">Lugar Expedición</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="expedicion_documento_rep_legal_rp" id="expedicion_documento_rep_legal_rp" defaultValue={formulario.expedicion_documento_rep_legal_rp}
                                                                    className={((this.verificarError("error_expedicion_documento_rep_legal_rp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Lugar Expedición" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Lugar Expedición</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-4">
                                                                <label htmlFor="cargo_rep_legal_rp">Cargo Representante Legal</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="cargo_rep_legal_rp" id="cargo_rep_legal_rp" defaultValue={formulario.cargo_rep_legal_rp}
                                                                    className={((this.verificarError("error_cargo_rep_legal_rp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Cargo Representante Legal" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} />
                                                                <small id="helpId" className="invalid-feedback">Digite el Cargo Representante Legal</small>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="correo_electronico_rep_legal_rp">Correo Electrónico Representante Legal</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_rep_legal_rp" id="correo_electronico_rep_legal_rp" defaultValue={formulario.correo_electronico_rep_legal_rp}
                                                                        className={((this.verificarError("error_correo_electronico_rep_legal_rp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico Representante Legal" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico Representante Legal</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="nav-inf_financiera_proveedor" role="tabpanel" aria-labelledby="nav-inf_financiera_proveedor-tab">
                                                <div style={{ marginTop: 15 }} className="row">
                                                    <div className="col-xl-12">
                                                        <div className="row g-3">
                                                            <div className="col-md-2">
                                                                <label htmlFor="fecha_reporte_fp">Fecha Reporte</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_reporte_fp" id="fecha_reporte_fp" defaultValue={formulario.fecha_reporte_fp}
                                                                        className={((this.verificarError("error_fecha_reporte_fp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Reporte" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Fecha Reporte</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className="row g-3">
                                                            <div className="col-md-2">
                                                                <label htmlFor="total_activos_fp">Total Activos</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="number" name="total_activos_fp" id="total_activos_fp" defaultValue={formulario.total_activos_fp}
                                                                        className={((this.verificarError("error_total_activos_fp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Total Activos" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Total Activos</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="total_pasivos_fp">Total Pasivos</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="number" name="total_pasivos_fp" id="total_pasivos_fp" defaultValue={formulario.total_pasivos_fp}
                                                                        className={((this.verificarError("error_total_pasivos_fp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Total Pasivos" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Total Pasivos</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="patrimonio_fp">Patrimonio</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="number" name="patrimonio_fp" id="patrimonio_fp" defaultValue={formulario.patrimonio_fp}
                                                                        className={((this.verificarError("error_patrimonio_fp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Patrimonio" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Patrimonio</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="ingresos_fp">Ingresos</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="number" name="ingresos_fp" id="ingresos_fp" defaultValue={formulario.ingresos_fp}
                                                                        className={((this.verificarError("error_ingresos_fp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Ingresos" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite los Ingresos</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className="row g-3">
                                                            <div className="col-md-2">
                                                                <label htmlFor="otros_ingresos_fp">Otros Ingresos</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="number" name="otros_ingresos_fp" id="otros_ingresos_fp" defaultValue={formulario.otros_ingresos_fp}
                                                                        className={((this.verificarError("error_otros_ingresos_fp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otros Ingresos" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite los Otros Ingresos</small>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="origen_otros_ingresos_fp">Origen Otros Ingresos</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="origen_otros_ingresos_fp" id="origen_otros_ingresos_fp" defaultValue={formulario.origen_otros_ingresos_fp}
                                                                    className={((this.verificarError("error_origen_otros_ingresos_fp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Origen Otros Ingresos" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                                <small id="helpId" className="invalid-feedback">Digite el Origen Otros Ingresos</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="egresos_fp">Egresos</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faDollarSign} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="number" name="egresos_fp" id="egresos_fp" defaultValue={formulario.egresos_fp}
                                                                        className={((this.verificarError("error_egresos_fp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Egresos" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite los Egresos</small>
                                                            </div>
                                                        </div>
                                                        <hr style={{ marginBottom: 0 }} />
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className="col-md-4">
                                                                <label htmlFor="diligenciador_fp">Diligenciador</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="diligenciador_fp" id="diligenciador_fp" defaultValue={formulario.diligenciador_fp}
                                                                    className={((this.verificarError("error_diligenciador_fp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Diligenciador" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} />
                                                                <small id="helpId" className="invalid-feedback">Digite el Diligenciador</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="tipo_documento_diligenciador_fp">Tipo Documento</label>
                                                                <select name="tipo_documento_diligenciador_fp"
                                                                    id="tipo_documento_diligenciador_fp"
                                                                    value={formulario.tipo_documento_diligenciador_fp}
                                                                    options={formulario.tipo_documento_diligenciador_fp}
                                                                    className={((this.verificarError("error_tipo_documento_diligenciador_fp")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Tipo Documento</option>
                                                                        <option value="1">C.C.</option>
                                                                        <option value="2">C.E.</option>
                                                                        <option value="3">NIT</option>
                                                                        <option value="4">P.P.</option>
                                                                        <option value="5">OTRO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="numero_documento_diligenciador_fp">Número Documento</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="numero_documento_diligenciador_fp" id="numero_documento_diligenciador_fp" defaultValue={formulario.numero_documento_diligenciador_fp}
                                                                        className={((this.verificarError("error_numero_documento_diligenciador_fp")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="habeas_data_fp">Habeas Data</label>
                                                                <select name="habeas_data_fp"
                                                                    id="habeas_data_fp"
                                                                    value={formulario.habeas_data_fp}
                                                                    options={formulario.habeas_data_fp}
                                                                    className={((this.verificarError("error_habeas_data_fp")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Habeas Data</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Habeas Data</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="nav-accionistas_empresa" role="tabpanel" aria-labelledby="nav-accionistas_empresa-tab">
                                                <div style={{ marginTop: 15 }} className="row">
                                                    <div className="col-xl-12">
                                                        <div className="row g-3">
                                                            <div className='col-md-12'>
                                                                <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoAccionistaModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Accionista</button>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <table className="table table-hover table-condensed table-sm">
                                                                    <thead className="thead-inverse">
                                                                        <tr>
                                                                            <th className="align-middle">NOMBRE</th>
                                                                            <th className="align-middle">TIPO IDENTIFICACIÓN</th>
                                                                            <th className="align-middle">IDENTIFICACIÓN</th>
                                                                            <th className="align-middle">CAPITAL %</th>
                                                                            <th className="align-middle">OPCIONES</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            accionistas.map((itemAccionistas) => (
                                                                                <tr key={itemAccionistas.id}>
                                                                                    <td>{itemAccionistas.nombre}</td>
                                                                                    {
                                                                                        (() => {
                                                                                            switch (itemAccionistas.tipo_identificacion) {
                                                                                                case 1:
                                                                                                    return (<td>C.C.</td>)
                                                                                                case 2:
                                                                                                    return (<td>C.E.</td>)
                                                                                                case 3:
                                                                                                    return (<td>NIT</td>)
                                                                                                case 4:
                                                                                                    return (<td>P.P.</td>)
                                                                                                case 5:
                                                                                                    return (<td>OTRO</td>)
                                                                                            }
                                                                                        })()
                                                                                    }
                                                                                    <td>{itemAccionistas.identificacion}</td>
                                                                                    <td>{itemAccionistas.capital}</td>
                                                                                    <td>
                                                                                        <div className="btn-group" role="group" aria-label="">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-danger btn-sm"
                                                                                                onClick={() =>
                                                                                                    this.borrarRegistrosPersona('accionista', itemAccionistas.id)
                                                                                                }
                                                                                            >
                                                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="nav-miembros_junta_directiva_empresa" role="tabpanel" aria-labelledby="nav-miembros_junta_directiva_empresa-tab">
                                                <div style={{ marginTop: 15 }} className="row">
                                                    <div className="col-xl-12">
                                                        <div className="row g-3">
                                                            <div className='col-md-12'>
                                                                <button type='button' className="btn btn-success btn-sm" data-bs-toggle='modal' data-bs-target="#NuevoMiembroJuntaDirectivaModal" slot='end'><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Miembro Junta Directiva</button>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <table className="table table-hover table-condensed table-sm">
                                                                    <thead className="thead-inverse">
                                                                        <tr>
                                                                            <th className="align-middle">NOMBRE</th>
                                                                            <th className="align-middle">TIPO IDENTIFICACIÓN</th>
                                                                            <th className="align-middle">IDENTIFICACIÓN</th>
                                                                            <th className="align-middle">OPCIONES</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                            junta_directiva.map((itemJuntaDirectiva) => (
                                                                                <tr key={itemJuntaDirectiva.id}>
                                                                                    <td>{itemJuntaDirectiva.nombre}</td>
                                                                                    {
                                                                                        (() => {
                                                                                            switch (itemJuntaDirectiva.tipo_identificacion) {
                                                                                                case 1:
                                                                                                    return (<td>C.C.</td>)
                                                                                                case 2:
                                                                                                    return (<td>C.E.</td>)
                                                                                                case 3:
                                                                                                    return (<td>NIT</td>)
                                                                                                case 4:
                                                                                                    return (<td>P.P.</td>)
                                                                                                case 5:
                                                                                                    return (<td>OTRO</td>)
                                                                                            }
                                                                                        })()
                                                                                    }
                                                                                    <td>{itemJuntaDirectiva.identificacion}</td>
                                                                                    <td>
                                                                                        <div className="btn-group" role="group" aria-label="">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-danger btn-sm"
                                                                                                onClick={() =>
                                                                                                    this.borrarRegistrosPersona('junta_directiva', itemJuntaDirectiva.id)
                                                                                                }
                                                                                            >
                                                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="nav-transparencia_origen" role="tabpanel" aria-labelledby="nav-transparencia_origen-tab">
                                                <div style={{ marginTop: 15 }} className="row">
                                                    <div className="col-xl-12">
                                                        <div className="row g-3">
                                                            <div className="col-md-4">
                                                                <label htmlFor="contacto_declaraciones_to">Contacto Declaraciones</label>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="contacto_declaraciones_to" id="contacto_declaraciones_to" defaultValue={formulario.contacto_declaraciones_to}
                                                                    className={((this.verificarError("error_contacto_declaraciones_to")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Contacto Declaraciones" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} />
                                                                <small id="helpId" className="invalid-feedback">Digite el Contacto Declaraciones</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="tipo_documento_contacto_declaraciones_to">Tipo Documento</label>
                                                                <select name="tipo_documento_contacto_declaraciones_to"
                                                                    id="tipo_documento_contacto_declaraciones_to"
                                                                    value={formulario.tipo_documento_contacto_declaraciones_to}
                                                                    options={formulario.tipo_documento_contacto_declaraciones_to}
                                                                    className={((this.verificarError("error_tipo_documento_contacto_declaraciones_to")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione el Tipo Documento</option>
                                                                        <option value="1">C.C.</option>
                                                                        <option value="2">C.E.</option>
                                                                        <option value="3">NIT</option>
                                                                        <option value="4">P.P.</option>
                                                                        <option value="5">OTRO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <label htmlFor="numero_documento_contacto_declaraciones_to">Número Documento</label>
                                                                <div className="input-group input-group-sm">
                                                                    <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="numero_documento_contacto_declaraciones_to" id="numero_documento_contacto_declaraciones_to" defaultValue={formulario.numero_documento_contacto_declaraciones_to}
                                                                        className={((this.verificarError("error_numero_documento_contacto_declaraciones_to")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} max="37" />
                                                                </div>
                                                                <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                            </div>
                                                            <div className='col-md-2'>
                                                                <label htmlFor="veracidad_to">Veracidad</label>
                                                                <select name="veracidad_to"
                                                                    id="veracidad_to"
                                                                    value={formulario.veracidad_to}
                                                                    options={formulario.veracidad_to}
                                                                    className={((this.verificarError("error_veracidad_to")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Veracidad</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Veracidad</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="nav-funcionario_publico" role="tabpanel" aria-labelledby="nav-funcionario_publico-tab">
                                                <div style={{ marginTop: 15 }} className="row">
                                                    <div className="col-xl-12">
                                                        <div className="row g-3">
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="empleado_publico_fp">¿En los últimos 2 años, ha sido usted Empleado Público Nacional o Extranjero?&nbsp;
                                                                    <span>
                                                                        <a href='https://dapre.presidencia.gov.co/normativa/normativa/DECRETO%20830%20DEL%2026%20DE%20JULIO%20' target="_blank">Enlace PEP</a>
                                                                    </span>
                                                                </label>
                                                                <select name="empleado_publico_fp"
                                                                    id="empleado_publico_fp"
                                                                    value={formulario.empleado_publico_fp}
                                                                    options={formulario.empleado_publico_fp}
                                                                    className={((this.verificarError("error_empleado_publico_fp")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione Funcionario Público</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione Funcionario Público</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="beneficiario_final_to">De acuerdo con la anterior definición, indque si usted o alguno de sus asociados, accionistas, administradores, o si la sociedad registra como Beneficiarios Finales (En el sentido establecido en el art. 22 de la Ley 222 de 1995) algún PEP</label>
                                                                <select name="beneficiario_final_to"
                                                                    id="beneficiario_final_to"
                                                                    value={formulario.beneficiario_final_to}
                                                                    options={formulario.beneficiario_final_to}
                                                                    className={((this.verificarError("error_beneficiario_final_to")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione Beneficiario Final</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione Beneficiario Final</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="nav-declaracion_transp_pn" role="tabpanel" aria-labelledby="nav-declaracion_transp_pn-tab">
                                                <div style={{ marginTop: 15 }} className="row">
                                                    <div className="col-xl-12">
                                                        <div className="row g-3">
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta1_pn">1. Que mis recursos provienen de actividades Lícitas y están ligados al desarrollo normal de mis actividades, y que, por lo tanto, los mismos no provienen de ninguna actividad ilícita de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                <select name="pregunta1_pn"
                                                                    id="pregunta1_pn"
                                                                    value={formulario.pregunta1_pn}
                                                                    options={formulario.pregunta1_pn}
                                                                    className={((this.verificarError("error_pregunta1_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 1</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 1</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta2_pn">2. Que yo no he efectuado transacciones u operaciones consistentes o destinadas a la ejecución de actividades ilícitas de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique, o a favor de personas que ejecuten o estén relacionadas con la ejecución de dichas actividades ilícitas.</label>
                                                                <select name="pregunta2_pn"
                                                                    id="pregunta2_pn"
                                                                    value={formulario.pregunta2_pn}
                                                                    options={formulario.pregunta2_pn}
                                                                    className={((this.verificarError("error_pregunta2_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 2</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 2</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta3_pn">3. Que los recursos comprometidos para la ejecución del contrato o negocio jurídico acordado con TERMOMORICHAL S.A.S., GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, no provienen de ninguna actividad ilícita de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                <select name="pregunta3_pn"
                                                                    id="pregunta3_pn"
                                                                    value={formulario.pregunta3_pn}
                                                                    options={formulario.pregunta3_pn}
                                                                    className={((this.verificarError("error_pregunta3_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 3</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 3</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta4_pn">4. Que en la ejecución del contrato o el desarrollo de las actividades en que consiste el negocio jurídico o vínculo legal con TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, no contratare ni tendré vínculos de ningún tipo con terceros que realicen operaciones o cuyos recursos provengan de actividades ilícitas de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                <select name="pregunta4_pn"
                                                                    id="pregunta4_pn"
                                                                    value={formulario.pregunta4_pn}
                                                                    options={formulario.pregunta4_pn}
                                                                    className={((this.verificarError("error_pregunta4_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 4</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 4</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta5_pn">5. Que yo no me encuentro en las listas internacionales vinculantes para Colombia (listas de las Naciones Unidas, listas de la OFAC u otras) ni en listas restrictivas del orden nacional, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, facultada para efectuar las verificaciones que considere pertinentes y para dar por terminada cualquier relación comercial o jurídica si verifica que figuro en dichas listas.</label>
                                                                <select name="pregunta5_pn"
                                                                    id="pregunta5_pn"
                                                                    value={formulario.pregunta5_pn}
                                                                    options={formulario.pregunta5_pn}
                                                                    className={((this.verificarError("error_pregunta5_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 5</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 5</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta6_pn">6. Que no existe contra mi investigaciones o procesos penales por delitos relacionados con el LA, FT y/o contrabando, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, facultada para efectuar las verificaciones que considere pertinentes en bases de datos o informaciones públicas nacionales o internacionales y para dar por terminada cualquier relación comercial o jurídica si verifica que me encuentro o tengo investigaciones o procesos, o existen informaciones de dichas bases de datos públicas que puedan colocar a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, frente a un riesgo legal, de contagio o de reputación en LA/FT.</label>
                                                                <select name="pregunta6_pn"
                                                                    id="pregunta6_pn"
                                                                    value={formulario.pregunta6_pn}
                                                                    options={formulario.pregunta6_pn}
                                                                    className={((this.verificarError("error_pregunta6_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 6</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 6</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta7_pn">7. Que en el evento en que tenga conocimiento de alguna de las circunstancias descritas en los dos párrafos anteriores, estaré obligado a comunicarlo de inmediato a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP.</label>
                                                                <select name="pregunta7_pn"
                                                                    id="pregunta7_pn"
                                                                    value={formulario.pregunta7_pn}
                                                                    options={formulario.pregunta7_pn}
                                                                    className={((this.verificarError("error_pregunta7_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 7</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 7</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta8_pn">8. Que toda la documentación e información aportada para la celebración y ejecución del contrato o negocio jurídico con TERMOMORICHAL S.A.S. es veraz y exacta, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, facultada para efectuar las verificaciones que considere pertinentes y para dar por terminada cualquier relación comercial o jurídica si dicha información no puede ser confirmada o sea inexacta.</label>
                                                                <select name="pregunta8_pn"
                                                                    id="pregunta8_pn"
                                                                    value={formulario.pregunta8_pn}
                                                                    options={formulario.pregunta8_pn}
                                                                    className={((this.verificarError("error_pregunta8_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 8</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 8</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta9_pn">9. Que ninguna otra persona natural o jurídica, tiene interés no legitimo en el contrato o negocio jurídico que motiva la suscripción de la presente declaración.</label>
                                                                <select name="pregunta9_pn"
                                                                    id="pregunta9_pn"
                                                                    value={formulario.pregunta9_pn}
                                                                    options={formulario.pregunta9_pn}
                                                                    className={((this.verificarError("error_pregunta9_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 9</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 9</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta10_pn">10. Que conozco, declaro y acepto que TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, está en la obligación legal de solicitar las aclaraciones que estime pertinentes en el evento en que se presenten circunstancias con base en las cuales TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, pueda tener dudas razonables sobre mis operaciones, así como el origen de mis activos, evento en el cual suministrare las aclaraciones que sean del caso. Si estas no son satisfactorias, a juicio de TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, la autorizo para dar por terminada cualquier relación comercial o jurídica.</label>
                                                                <select name="pregunta10_pn"
                                                                    id="pregunta10_pn"
                                                                    value={formulario.pregunta10_pn}
                                                                    options={formulario.pregunta10_pn}
                                                                    className={((this.verificarError("error_pregunta10_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 10</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 10</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta11_pn">11. Que, con el diligenciamiento del presente documento, se entiende que yo como persona natural, otorgo consentimiento informado, y por lo tanto autorizo a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, para que verifique la información suministrada y en caso de ser requerida por autoridad nacional o internacional, y suministre toda la información personal, publica, privada o semiprivada que sobre mi ellas requieran.</label>
                                                                <select name="pregunta11_pn"
                                                                    id="pregunta11_pn"
                                                                    value={formulario.pregunta11_pn}
                                                                    options={formulario.pregunta11_pn}
                                                                    className={((this.verificarError("error_pregunta11_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 11</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 11</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta12_pn">12. Declaro que he sido informado por TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, acerca de mi obligación de cumplimiento de las normas internas y externas relacionadas con la prevención del Soborno Transnacional previstas en el Artículo 2° de la Ley 1778 de 2016, así como de cualquier otra práctica corrupta, y que conozco las consecuencias de infringirlo.</label>
                                                                <select name="pregunta12_pn"
                                                                    id="pregunta12_pn"
                                                                    value={formulario.pregunta12_pn}
                                                                    options={formulario.pregunta12_pn}
                                                                    className={((this.verificarError("error_pregunta12_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 12</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 12</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta13_pn">13. Que en el caso de ser considerado por TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDEN SAS ESP y ENELCA SAS ESP, como un proveedor o contratista con mayor grado de exposición al riesgo de Soborno Transnacional, autorizo para que se adelante procedimientos de debida diligencia dirigidos a determinar la forma en que cumplo con las obligaciones de prevención del Soborno Transnacional.</label>
                                                                <select name="pregunta13_pn"
                                                                    id="pregunta13_pn"
                                                                    value={formulario.pregunta13_pn}
                                                                    options={formulario.pregunta13_pn}
                                                                    className={((this.verificarError("error_pregunta13_pn")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 13</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 13</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="nav-declaracion_transp_pj" role="tabpanel" aria-labelledby="nav-declaracion_transp_pj-tab">
                                                <div style={{ marginTop: 15 }} className="row">
                                                    <div className="col-xl-12">
                                                        <div className="row g-3">
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta1_pj">1. Que mis recursos y los recursos de la persona jurídica que represento provienen de actividades Lícitas y están ligados al desarrollo normal de mis/sus actividades, y que, por lo tanto, los mismos no provienen de ninguna actividad ilícita de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                <select name="pregunta1_pj"
                                                                    id="pregunta1_pj"
                                                                    value={formulario.pregunta1_pj}
                                                                    options={formulario.pregunta1_pj}
                                                                    className={((this.verificarError("error_pregunta1_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 1</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 1</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta2_pj">2. Que yo o la persona jurídica que represento no he/ha efectuado transacciones u operaciones consistentes en o destinadas a la ejecución de actividades ilícitas de las contempladas en el Código Penal Colombiano o en cualquier norma que lo sustituya, adicione o modifique, o a favor de personas que ejecuten o estén relacionadas con la ejecución de dichas actividades ilícitas.</label>
                                                                <select name="pregunta2_pj"
                                                                    id="pregunta2_pj"
                                                                    value={formulario.pregunta2_pj}
                                                                    options={formulario.pregunta2_pj}
                                                                    className={((this.verificarError("error_pregunta2_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 2</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 2</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta3_pj">3. Que los recursos comprometidos para el pago de la ejecución del contrato o negocio jurídico acordado con TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S..A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS no provienen de ninguna actividad ilícita de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                <select name="pregunta3_pj"
                                                                    id="pregunta3_pj"
                                                                    value={formulario.pregunta3_pj}
                                                                    options={formulario.pregunta3_pj}
                                                                    className={((this.verificarError("error_pregunta3_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 3</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 3</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta4_pj">4. Que no contratare ni tendré vínculos de ningún tipo con terceros que realicen operaciones o cuyos recursos provengan de actividades ilícitas de las contempladas en el Código Penal colombiano o en cualquier norma que lo sustituya, adicione o modifique.</label>
                                                                <select name="pregunta4_pj"
                                                                    id="pregunta4_pj"
                                                                    value={formulario.pregunta4_pj}
                                                                    options={formulario.pregunta4_pj}
                                                                    className={((this.verificarError("error_pregunta4_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 4</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 4</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta5_pj">5. Que la entidad que represento cumple con las normas sobre prevención y control al Lavado de Activos y Financiación del Terrorismo (LA/FT/FPADM) que le resulten aplicables (de ser el caso), teniendo implementados las políticas, procedimientos y mecanismos de prevención y control al LA/FT/FPADM que se derivan de dichas disposiciones legales.</label>
                                                                <select name="pregunta5_pj"
                                                                    id="pregunta5_pj"
                                                                    value={formulario.pregunta5_pj}
                                                                    options={formulario.pregunta5_pj}
                                                                    className={((this.verificarError("error_pregunta5_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 5</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 5</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta6_pj">6. Que yo, ni la entidad que represento, ni sus accionistas, asociados o socios, sus representantes legales y miembros de junta directiva, se encuentran en las listas internacionales vinculantes para Colombia (listas de las Naciones Unidas, listas de la OFAC u otras) ni en listas restrictivas del orden nacional, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS facultada para efectuar las verificaciones que considere pertinentes para dar por terminada cualquier relación comercial o jurídica si verifica que me encuentro o que alguna de las personas mencionadas figura en dichas listas.</label>
                                                                <select name="pregunta6_pj"
                                                                    id="pregunta6_pj"
                                                                    value={formulario.pregunta6_pj}
                                                                    options={formulario.pregunta6_pj}
                                                                    className={((this.verificarError("error_pregunta6_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 6</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 6</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta7_pj">7. Que no existe contra mi o contra la entidad que represento ni sus accionistas, asociados o socios, sus representantes legales y sus miembros de la junta directiva, investigaciones o procesos penales por delitos relacionados con el LA, FT, FPADM, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS facultada para efectuar las verificaciones que considere pertinentes en bases de datos o informaciones públicas nacionales o internacionales y para dar por terminada cualquier relación comercial o jurídica si verifica que me encuentro o que alguna de las personas mencionadas tienen investigaciones o procesos, o existen informaciones de dichas bases de datos públicas que puedan colocar a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS frente a un riesgo legal, de contagio o de reputación en LA/FT/FPADM.</label>
                                                                <select name="pregunta7_pj"
                                                                    id="pregunta7_pj"
                                                                    value={formulario.pregunta7_pj}
                                                                    options={formulario.pregunta7_pj}
                                                                    className={((this.verificarError("error_pregunta7_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 7</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 7</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta8_pj">8. Que en el evento en que tenga conocimiento de alguna de las circunstancias descritas en los dos párrafos anteriores, estaré obligado a comunicarlo de inmediato a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS.</label>
                                                                <select name="pregunta8_pj"
                                                                    id="pregunta8_pj"
                                                                    value={formulario.pregunta8_pj}
                                                                    options={formulario.pregunta8_pj}
                                                                    className={((this.verificarError("error_pregunta8_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 8</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 8</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta9_pj">9. Que toda la documentación e información aportada para la celebración y ejecución del contrato o negocio jurídico con TERMOMORICHAL S.A.S. es veraz y exacta, estando TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS facultada para efectuar las verificaciones que considere pertinentes para dar por terminada cualquier relación comercial o jurídica si verifica, o tiene conocimiento de que ello no es así.</label>
                                                                <select name="pregunta9_pj"
                                                                    id="pregunta9_pj"
                                                                    value={formulario.pregunta9_pj}
                                                                    options={formulario.pregunta9_pj}
                                                                    className={((this.verificarError("error_pregunta9_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 9</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 9</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta10_pj">10. Que ninguna otra persona natural o jurídica, tiene interés no legitimo en el contrato o negocio jurídico que motiva la suscripción de la presente declaración.</label>
                                                                <select name="pregunta10_pj"
                                                                    id="pregunta10_pj"
                                                                    value={formulario.pregunta10_pj}
                                                                    options={formulario.pregunta10_pj}
                                                                    className={((this.verificarError("error_pregunta10_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 10</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 10</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta11_pj">11. Que conozco, declaro y acepto que TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS está en la obligación legal de solicitar las aclaraciones que estime pertinentes en el evento en que se presenten circunstancias con base en las cuales TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS pueda tener dudas razonables sobre mis operaciones o las operaciones de la persona jurídica que represento, así como el origen de nuestros activos, evento en el cual suministraremos las aclaraciones que sean del caso. Si estas no son satisfactorias, a juicio de TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS, la autorizamos para dar por terminada cualquier relación comercial o jurídica.</label>
                                                                <select name="pregunta11_pj"
                                                                    id="pregunta11_pj"
                                                                    value={formulario.pregunta11_pj}
                                                                    options={formulario.pregunta11_pj}
                                                                    className={((this.verificarError("error_pregunta11_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 11</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 11</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta12_pj">12. Que con la firma del presente documento, se entiende que como persona jurídica, otorgo consentimiento informado, y por lo tanto autorizo a TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS para que verifique la información suministrada y en caso de ser requerida por autoridad nacional o internacional, y suministre toda la información personal, publica, privada o semiprivada que se requiera.</label>
                                                                <select name="pregunta12_pj"
                                                                    id="pregunta12_pj"
                                                                    value={formulario.pregunta12_pj}
                                                                    options={formulario.pregunta12_pj}
                                                                    className={((this.verificarError("error_pregunta12_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 12</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 12</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta13_pj">13. La persona Jurídica declara que ha sido informada por TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS, acerca de sus obligaciones de cumplimiento de las normas internas y externas relacionadas con la prevención del Soborno Transnacional previstas en el Artículo 2° de la Ley 1778 de 2016, así como de cualquier otra práctica corrupta, y que conoce las consecuencias de infringirlo.</label>
                                                                <select name="pregunta13_pj"
                                                                    id="pregunta13_pj"
                                                                    value={formulario.pregunta13_pj}
                                                                    options={formulario.pregunta13_pj}
                                                                    className={((this.verificarError("error_pregunta13_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 13</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 13</small>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: 0 }} className='row g-3'>
                                                            <div className='col-md-8'>
                                                                <label style={{ textAlign: 'justify' }} htmlFor="pregunta14_pj">14. Que en el caso que la Persona Jurídica que represento sea considerado por TERMOMORICHAL S.A.S. GENSER POWER S.A.S. E.S.P., GENSER POWER COLOMBIA Y PROELECTRICA S.A.S. E.S.P., CENTRAL HIDROELÉCTRICA EL EDÉN SAS ESP Y ENELCA SAS, como un proveedor o contratista con mayor grado de exposición al riesgo de Soborno Transnacional, autoriza para que se adelante procedimientos de debida diligencia dirigidos a determinar la forma en que cumple con las obligaciones de prevención del Soborno Transnacional.</label>
                                                                <select name="pregunta14_pj"
                                                                    id="pregunta14_pj"
                                                                    value={formulario.pregunta14_pj}
                                                                    options={formulario.pregunta14_pj}
                                                                    className={((this.verificarError("error_pregunta14_pj")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    onChange={this.cambioValor}>
                                                                        <option value="">Seleccione la Pregunta 14</option>
                                                                        <option value="1">SI</option>
                                                                        <option value="2">NO</option>
                                                                </select>
                                                                <small id="helpId" className="invalid-feedback">Seleccione la Pregunta 14</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='row'>
                                            <div className='col-xl-12 text-center'>
                                                <div className="btn-group" role="group" aria-label="">
                                                    <button type="submit" className="btn btn-success btn-sm"><FontAwesomeIcon icon={faPaperPlane} />&nbsp;&nbsp;Enviar</button>&nbsp;&nbsp;
                                                    <Link className="btn btn-danger btn-sm" to={"/MasterTerceros"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoAccionistaModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoAccionistaModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoAccionistaModalLabel">Nuevo Accionista</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form id='form-accionistas'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS ACCIONISTA
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="accionista_ae">Accionista</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="accionista_ae" id="accionista_ae" value={accionista_ae}
                                                                        className={((this.verificarError("error_accionista_ae")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Accionista" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Accionista</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className='col-md-3'>
                                                                    <label htmlFor="tipo_documento_accionista_ae">Tipo Documento</label>
                                                                    <select name="tipo_documento_accionista_ae"
                                                                        id="tipo_documento_accionista_ae"
                                                                        className={((this.verificarError("error_tipo_documento_accionista_ae")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="1">C.C.</option>
                                                                            <option value="2">C.E.</option>
                                                                            <option value="3">NIT</option>
                                                                            <option value="4">P.P.</option>
                                                                            <option value="5">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numero_documento_accionista_ae">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="numero_documento_accionista_ae" id="numero_documento_accionista_ae" value={numero_documento_accionista_ae}
                                                                            className={((this.verificarError("error_numero_documento_accionista_ae")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <label htmlFor="digito_verificacion_ae">DV</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="digito_verificacion_ae" id="digito_verificacion_ae" value={digito_verificacion_ae}
                                                                            className={((this.verificarError("error_digito_verificacion_ae")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="DV" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el DV</small>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <label htmlFor="capital_accionista_ae">Capital %</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faPercentage} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="capital_accionista_ae" id="capital_accionista_ae" value={capital_accionista_ae}
                                                                            className={((this.verificarError("error_capital_accionista_ae")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Capital %" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Capital %</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosAccionistas}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Crear Accionista</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="NuevoMiembroJuntaDirectivaModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="NuevoMiembroJuntaDirectivaModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title w-100 text-center" id="NuevoMiembroJuntaDirectivaModalLabel">Nuevo Miembro Junta Directiva</h4>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form id='form-junta_directiva'>
                                    <div className='row'>
                                        <div className='col-xl-12'>
                                            <div className="card">
                                                <div className="card-header text-center">
                                                    DATOS MIEMBRO JUNTA DIRECTIVA
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <div className="row g-3">
                                                                <div className="col-md-6">
                                                                    <label htmlFor="miembro_junta_directiva_jd">Miembro Junta Directiva</label>
                                                                    <input type="hidden" name='id_formulario' id='id_formulario' value={this.props.match.params.id} />
                                                                    <input style={{ textTransform: 'uppercase' }} type="text" name="miembro_junta_directiva_jd" id="miembro_junta_directiva_jd" value={miembro_junta_directiva_jd}
                                                                        className={((this.verificarError("error_miembro_junta_directiva_jd")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Miembro Junta Directiva" aria-describedby="helpId"
                                                                        onChange={this.cambioValor} />
                                                                    <small id="helpId" className="invalid-feedback">Digite el Miembro Junta Directiva</small>
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 0 }} className='row g-3'>
                                                                <div className='col-md-3'>
                                                                    <label htmlFor="tipo_documento_miembro_junta_directiva_jd">Tipo Documento</label>
                                                                    <select name="tipo_documento_miembro_junta_directiva_jd"
                                                                        id="tipo_documento_miembro_junta_directiva_jd"
                                                                        className={((this.verificarError("error_tipo_documento_miembro_junta_directiva_jd")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                        onChange={this.cambioValor}>
                                                                            <option value="">Seleccione el Tipo Documento</option>
                                                                            <option value="1">C.C.</option>
                                                                            <option value="2">C.E.</option>
                                                                            <option value="3">NIT</option>
                                                                            <option value="4">P.P.</option>
                                                                            <option value="5">OTRO</option>
                                                                    </select>
                                                                    <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <label htmlFor="numero_documento_miembro_junta_directiva_jd">Número Documento</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="numero_documento_miembro_junta_directiva_jd" id="numero_documento_miembro_junta_directiva_jd" value={numero_documento_miembro_junta_directiva_jd}
                                                                            className={((this.verificarError("error_numero_documento_miembro_junta_directiva_jd")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Documento" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el Número Documento</small>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <label htmlFor="digito_verificacion_jd">DV</label>
                                                                    <div className="input-group input-group-sm">
                                                                        <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                        <input style={{ textTransform: 'uppercase' }} type="text" name="digito_verificacion_jd" id="digito_verificacion_jd" value={digito_verificacion_jd}
                                                                            className={((this.verificarError("error_digito_verificacion_jd")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="DV" aria-describedby="helpId"
                                                                            onChange={this.cambioValor} max="37" />
                                                                    </div>
                                                                    <small id="helpId" className="invalid-feedback">Digite el DV</small>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className='row'>
                                                                <div className='col-xl-12 text-center'>
                                                                    <div className="btn-group" role="group" aria-label="">
                                                                        <button type="button" className="btn btn-success btn-sm" onClick={this.enviarDatosJuntaDirectiva}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Crear Miembro Junta Directiva</button>&nbsp;&nbsp;
                                                                        <button type='button' className="btn btn-danger btn-sm" data-bs-dismiss="modal"><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default CrearTerceros;