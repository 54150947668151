import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faEdit, faFilter, faPlus, faTrashAlt, faClipboardList } from '@fortawesome/free-solid-svg-icons';

class Listar extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        load: '',
        datosCargados: false,
        roles: [],
        Modulos: []
    }
    borrarRegistros = (id) => {
        Swal.fire({
            title: 'Está seguro de Eliminar el Rol?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Borrar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                authAxios.get("roles/delete/" + id)
                    .then((datosRespuesta) => {
                        console.log(datosRespuesta);
                        Swal.fire('Borrado!', datosRespuesta.data.msj, 'success')
                        this.ListarRoles(1);
                    })
                    .catch(console.log);
            } else if (result.isDenied) {
                Swal.fire('Cancelado', '', 'info')
            }
        })
    };
    ListarRoles(page) {
        Swal.showLoading();
        let bname = document.getElementById('bnombre').value;
        if (bname == '') {
            bname = 0;
        }
        authAxios.get("roles/index/" + bname + "?page=" + page)
            .then((datosRespuesta) => {
                Swal.close();
                var status = datosRespuesta.data.status;
                if (status) {
                    Swal.fire('Token ha Expirado!', 'Vuelva a iniciar session', 'info');
                    window.localStorage.clear();
                    window.location.href = '/';
                }
                console.log(status);
                this.setState({ datosCargados: true, load: '', roles: datosRespuesta.data.data.data, pagina: datosRespuesta.data.data.total, ultimo: datosRespuesta.data.data.last_page, actual: datosRespuesta.data.data.current_page });
            })
            .catch(console.log);
        //window.location.href = '/'; window.localStorage.clear();
    }
    componentDidMount() {
        this.ListarRoles(1);
    }
    render() {
        const { load, roles, pagina, ultimo, actual, Modulos } = this.state;
        return (
            <div className="content">
                <div className="container-fluid">
                    <br />
                    <div className="card">
                        <div className="card-header">
                            <Link className="btn btn-success btn-sm" to={"/AddRoles"}><FontAwesomeIcon icon={faPlus} />&nbsp;&nbsp;Nuevo Rol</Link>
                        </div>
                        <div className="card-body">
                            <h4>Lista Roles</h4>
                            <hr />
                            <div className="row">
                                <div className="col-xl-3">
                                    <input style={{ textTransform: 'uppercase' }} type="text" id="bnombre" name="bnombre" placeholder="ROL" className="form-control form-control-sm" />
                                </div>
                                <div className="col-xl-3">
                                    <button id="bt" name="bt" className="btn btn-secondary btn-sm" onClick={() => this.ListarRoles(1)}><FontAwesomeIcon icon={faFilter} />&nbsp;&nbsp;Filtrar</button>
                                </div>
                            </div>
                            <br></br>
                            <div className="row">
                                <table className="table table-hover table-condensed table-sm">
                                    <thead className="thead-inverse">
                                        <tr>
                                            <th className="align-middle">NOMBRE</th>
                                            <th className="align-middle">DESCRIPCIÓN</th>
                                            <th className="align-middle">OPCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {load}
                                        {
                                            roles.map((itemRoles) => (
                                                <tr key={itemRoles.id}>
                                                    <td>{itemRoles.nombre}</td>
                                                    <td>{itemRoles.descripcion}</td>
                                                    <td>
                                                        <div className="btn-group" role="group" aria-label="">
                                                            <Link
                                                                className="btn btn-primary btn-sm"
                                                                to={"/UpRoles/" + itemRoles.id}
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </Link>&nbsp;&nbsp;&nbsp;
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() =>
                                                                    this.borrarRegistros(itemRoles.id)
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarRoles(1)}><FontAwesomeIcon icon={faAngleDoubleLeft} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarRoles(actual - 1)}><FontAwesomeIcon icon={faAngleLeft} /></a></li>
                                    {(() => {
                                        let p = [];
                                        p.push(<li className="page-item" key={actual}><a className="page-link" href="#" onClick={() => this.BuscarRol(actual)}>{actual}</a></li>);
                                        return p;
                                    })()}
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarRoles(actual + 1)}><FontAwesomeIcon icon={faAngleRight} /></a></li>
                                    <li className="page-item"><a className="page-link" href="#" onClick={() => this.ListarRoles(ultimo)}><FontAwesomeIcon icon={faAngleDoubleRight} /></a></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="card-footer text-muted">
                            <b>Pagina</b> <span className="badge bg-primary">{actual}</span> <b>de</b> <span className="badge bg-primary">{ultimo}</span> | <b>Registros</b> <span className="badge bg-success">{pagina}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Listar;