import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import authAxios from "../../services/Token";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faCalendarAlt, faDollarSign, faFolderOpen, faFont, faGlobe, faHashtag, faPaperPlane, faPercentage, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

class CrearTerceros extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        tipo_formulario: '',
        fecha_diligenciamiento: '',
        tipo_contraparte: '',
        tipo_proveedor: '',
        proveedor_otras: '',
        bien_servicio: '',
        usuario: '',
        contrasena: '',
        correo_electronico: '',
        terminos_condiciones: '',
        titularidad: '',
        compromiso: '',
        numero_identificacion: '',
        digito_verificacion: '',
        tipo_persona: '',
        tipo_documento: '',
        numero_identificacion_2: '',
        fecha_expedicion: '',
        id_departamento_expedicion: '',
        id_municipio_expedicion: '',
        primer_apellido: '',
        segundo_apellido: '',
        primer_nombre: '',
        segundo_nombre: '',
        otro_nombre: '',
        razon_social: '',
        tipo_empresa: '',
        tipo_sociedad: '',
        nombre_comercial: '',
        siglas: '',
        id_departamento_funcionamiento: '',
        id_municipio_funcionamiento: '',
        id_direccion_empresa_1: '',
        direccion_empresa_1: '',
        complemento_direccion_empresa_1: '',
        id_direccion_empresa_2: '',
        direccion_empresa_2: '',
        complemento_direccion_empresa_2: '',
        direccion_empresa_3: '',
        complemento_direccion_empresa: '',
        correo_electronico_empresa: '',
        telefono_1: '',
        telefono_2: '',
        pagina_web: '',
        files_rut: '',
        files_camara: '',
        departamentos: [],
        municipios: [],
        departamentos_funcionamiento: [],
        municipios_funcionamiento: [],
        errores: []
    }
    cambioValor = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState({ state, errores: [] })
    }
    cambioValorFiles = (e) => {
        this.setState({ files: e.target.files });
    }
    verificarError(elemento) {
        return this.state.errores.indexOf(elemento) !== -1;
    }
    verifyFileSize = (e) => {
        let file = e.target.files[0];
        if (file && !file.name) {
            window.alert("Please select a file");
            return false;
        }
        if (file.size > 10e6) {
            Swal.fire(
                'Cargue Archivo',
                'El tamaño del archivo sobrepasa los 10MB. Favor verificar.',
                'error'
            )
            e.target.value = null;
            return false;
        }
    }
    enviarDatos = (e) => {
        e.preventDefault();
        const { tipo_contraparte, tipo_proveedor, proveedor_otras, bien_servicio, usuario, contrasena, correo_electronico,
                terminos_condiciones, titularidad, compromiso, numero_identificacion, digito_verificacion, tipo_persona, tipo_documento, numero_identificacion_2,
                fecha_expedicion, id_departamento_expedicion, id_municipio_expedicion, primer_apellido, segundo_apellido, primer_nombre, segundo_nombre, otro_nombre,
                razon_social, tipo_empresa, tipo_sociedad, nombre_comercial, siglas, id_departamento_funcionamiento, id_municipio_funcionamiento, id_direccion_empresa_1,
                id_direccion_empresa_2, direccion_empresa_1, direccion_empresa_2, direccion_empresa_3, complemento_direccion_empresa_1, complemento_direccion_empresa_2,
                complemento_direccion_empresa, correo_electronico_empresa, telefono_1, telefono_2, pagina_web, files_rut, files_camara
        } = this.state;
        var errores = [];
        if (!tipo_contraparte) { errores.push("error_tipo_contraparte"); }
        if (!tipo_proveedor) { errores.push("error_tipo_proveedor"); }
        if (!proveedor_otras) { errores.push("error_proveedor_otras"); }
        if (!bien_servicio) { errores.push("error_bien_servicio"); }
        if (!usuario) { errores.push("error_usuario"); }
        if (!contrasena) { errores.push("error_contrasena"); }
        if (!correo_electronico) { errores.push("error_correo_electronico"); }
        if (!terminos_condiciones) { errores.push("error_terminos_condiciones"); }
        if (!titularidad) { errores.push("error_titularidad"); }
        if (!compromiso) { errores.push("error_compromiso"); }
        if (!numero_identificacion) { errores.push("error_numero_identificacion"); }
        if (!digito_verificacion) { errores.push("error_digito_verificacion"); }
        if (!tipo_persona) { errores.push("error_tipo_persona"); }
        if (!tipo_documento) { errores.push("error_tipo_documento"); }
        if (!numero_identificacion_2) { errores.push("error_numero_identificacion_2"); }
        if (!fecha_expedicion) { errores.push("error_fecha_expedicion"); }
        if (!id_departamento_expedicion) { errores.push("error_id_departamento_expedicion"); }
        if (!id_municipio_expedicion) { errores.push("error_id_municipio_expedicion"); }
        if (!primer_apellido) { errores.push("error_primer_apellido"); }
        if (!segundo_apellido) { errores.push("error_segundo_apellido"); }
        if (!primer_nombre) { errores.push("error_primer_nombre"); }
        if (!segundo_nombre) { errores.push("error_segundo_nombre"); }
        if (!otro_nombre) { errores.push("error_otro_nombre"); }
        if (!razon_social) { errores.push("error_razon_social"); }
        if (!tipo_empresa) { errores.push("error_tipo_empresa"); }
        if (!tipo_sociedad) { errores.push("error_tipo_sociedad"); }
        if (!nombre_comercial) { errores.push("error_nombre_comercial"); }
        if (!siglas) { errores.push("error_siglas"); }
        if (!id_departamento_funcionamiento) { errores.push("error_id_departamento_funcionamiento"); }
        if (!id_municipio_funcionamiento) { errores.push("error_id_municipio_funcionamiento"); }
        if (!id_direccion_empresa_1) { errores.push("error_id_direccion_empresa_1"); }
        if (!id_direccion_empresa_2) { errores.push("error_id_direccion_empresa_2"); }
        if (!direccion_empresa_1) { errores.push("error_direccion_empresa_1"); }
        if (!direccion_empresa_2) { errores.push("error_direccion_empresa_2"); }
        if (!direccion_empresa_3) { errores.push("error_direccion_empresa_3"); }
        if (!complemento_direccion_empresa_1) { errores.push("error_complemento_direccion_empresa_1"); }
        if (!complemento_direccion_empresa_2) { errores.push("error_complemento_direccion_empresa_2"); }
        if (!complemento_direccion_empresa) { errores.push("error_complemento_direccion_empresa"); }
        if (!correo_electronico_empresa) { errores.push("error_correo_electronico_empresa"); }
        if (!telefono_1) { errores.push("error_telefono_1"); }
        if (!telefono_2) { errores.push("error_telefono_2"); }
        if (!pagina_web) { errores.push("error_pagina_web"); }
        if (!files_rut) { errores.push("error_files_rut"); }
        if (!files_camara) { errores.push("error_files_camara"); }
        this.setState({ errores: errores });
        if (errores.length > 0) {
            return false;
        }
        
        Swal.showLoading();
        // authAxios.post("fact_aportes/registrar", datosEnviar)
        //     .then((datosRespuesta) => {
        //         console.log(datosRespuesta)
        //         var status = datosRespuesta.data.status;
        //         if (status) {
        //             Swal.close();
        //             Swal.fire('Token ha Expirado!', 'Vuelva a Iniciar Sesión', 'info');
        //             window.localStorage.clear();
        //             window.location.href = '/';
        //         }
        //         if (datosRespuesta.data.Status) {
        //             Swal.close();
        //             Swal.fire(
        //                 'Facturación Aportes Municipales!',
        //                 datosRespuesta.data.msj,
        //                 'success'
        //             )
        //             this.props.history.push("/MasterFactAportesMunicipales");
        //         } else {
        //             Swal.close();
        //             Swal.fire(
        //                 'Facturación Aportes Municipales!',
        //                 datosRespuesta.data.msj,
        //                 'error'
        //             )
        //         }
        //     })
        //     .catch(console.log)
    }
    cargarMunicipios() {
        var id_departamento = document.getElementById('id_departamento').value;
        Swal.showLoading();
        authAxios.get("municipios_visitas/buscardepartamento/" + id_departamento)
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, municipiosApor: datosRespuesta.data, load: '' });
                Swal.close();
            })
            .catch(console.log);
    }
    componentDidMount() {
        Swal.showLoading();
        const date = new Date();
        const newDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        this.setState({ fecha_diligenciamiento : newDate });
        axios.get("https://www.datos.gov.co/resource/xd7j-ypd4.json")
            .then((datosRespuesta) => {
                console.log(datosRespuesta);
                this.setState({ datosCargados: true, departamentos: datosRespuesta.data, departamentos_funcionamiento: datosRespuesta.data, load: '' });
                Swal.close();
            })
            .catch(console.log());
        Swal.close();
    }
    render() {
        const { tipo_formulario, fecha_diligenciamiento, tipo_contraparte, tipo_proveedor, proveedor_otras, bien_servicio, usuario, contrasena, correo_electronico,
                terminos_condiciones, titularidad, compromiso, numero_identificacion, digito_verificacion, tipo_persona, tipo_documento, numero_identificacion_2,
                fecha_expedicion, id_departamento_expedicion, id_municipio_expedicion, primer_apellido, segundo_apellido, primer_nombre, segundo_nombre, otro_nombre,
                razon_social, tipo_empresa, tipo_sociedad, nombre_comercial, siglas, id_departamento_funcionamiento, id_municipio_funcionamiento, id_direccion_empresa_1,
                id_direccion_empresa_2, direccion_empresa_1, direccion_empresa_2, direccion_empresa_3, complemento_direccion_empresa_1, complemento_direccion_empresa_2,
                complemento_direccion_empresa, correo_electronico_empresa, telefono_1, telefono_2, pagina_web, files_rut, files_camara,
                departamentos, municipios, departamentos_funcionamiento, municipios_funcionamiento
            } = this.state;
        return (
            <div className="content-wrapper">
                <div className="content">
                    <div className="container-fluid">
                        <br />
                        <div className="card">
                            <div className="card-header text-center">
                                <b>REGISTRO DE TERCEROS</b>
                            </div>
                            <div className="card-body">
                                <form onSubmit={this.enviarDatos} id='form-tercero'>
                                    <nav>
                                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button class="nav-link active" id="nav-usuario-tab" data-bs-toggle="tab" data-bs-target="#nav-usuario" type="button" role="tab" aria-controls="nav-usuario" aria-selected="true">Usuario</button>
                                            <button class="nav-link" id="nav-datos_generales-tab" data-bs-toggle="tab" data-bs-target="#nav-datos_generales" type="button" role="tab" aria-controls="nav-datos_generales" aria-selected="false">Datos Generales</button>
                                        </div>
                                    </nav>
                                    <div class="tab-content" id="nav-tabContent">
                                        <div class="tab-pane fade show active" id="nav-usuario" role="tabpanel" aria-labelledby="nav-usuario-tab">
                                            <div style={{ marginTop: 15 }} className="row">
                                                <div className="col-xl-12">
                                                    <div className='row g-3'>
                                                        <div className="col-md-2">
                                                            <label htmlFor="fecha_diligenciamiento">Fecha Diligenciamiento</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="fecha_diligenciamiento" id="fecha_diligenciamiento" value={fecha_diligenciamiento}
                                                                    className={((this.verificarError("error_fecha_diligenciamiento")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Diligenciamiento" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} readOnly />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Seleccione la Fecha Diligenciamiento</small>
                                                        </div>
                                                        <div className='col-md-7'></div>
                                                        <div className='col-md-3'>
                                                            <label htmlFor="tipo_formulario">Tipo Formulario</label>
                                                            <select name="tipo_formulario"
                                                                id="tipo_formulario"
                                                                className={((this.verificarError("error_tipo_formulario")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={this.cambioValor} disabled>
                                                                <option value="">Seleccione el Tipo Formulario</option>
                                                                <option value="1" selected>PRIMERA VEZ</option>
                                                                <option value="2">ACTUALIZACIÓN</option>
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Formulario</small>
                                                        </div>
                                                    </div>
                                                    <hr style={{ marginBottom: 0 }} />
                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                        <div className='col-md-3'>
                                                            <label htmlFor="tipo_contraparte">Tipo Contraparte</label>
                                                            <select name="tipo_contraparte"
                                                                id="tipo_contraparte"
                                                                className={((this.verificarError("error_tipo_contraparte")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={this.cambioValor}>
                                                                <option value="">Seleccione el Tipo Contraparte</option>
                                                                <option value="1">ACCIONISTA</option>
                                                                <option value="2">CLIENTE</option>
                                                                <option value="3">EMPLEADO</option>
                                                                <option value="4">MIEMBRO JUNTA DIRECTIVA</option>
                                                                <option value="5">PROVEEDOR EXTERIOR</option>
                                                                <option value="6">PROVEEDOR NACIONAL JURÍDICO</option>
                                                                <option value="7">PROVEEDOR NACIONAL NATURAL</option>
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Contraparte</small>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <label htmlFor="tipo_proveedor">Tipo Proveedor</label>
                                                            <select name="tipo_proveedor"
                                                                id="tipo_proveedor"
                                                                className={((this.verificarError("error_tipo_proveedor")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={this.cambioValor}>
                                                                <option value="">Seleccione el Tipo Proveedor</option>
                                                                <option value="1">BIENES</option>
                                                                <option value="2">BIENES Y SERVICIOS</option>
                                                                <option value="3">OTRAS</option>
                                                                <option value="4">SERVICIOS</option>
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Proveedor</small>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="proveedor_otras">Otras</label>
                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="proveedor_otras" id="proveedor_otras" value={proveedor_otras}
                                                                className={((this.verificarError("error_proveedor_otras")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Otras" aria-describedby="helpId"
                                                                onChange={this.cambioValor} />
                                                            <small id="helpId" className="invalid-feedback">Digite la Otra Opción</small>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <label htmlFor="bien_servicio">Bien - Servicio</label>
                                                            <select name="bien_servicio"
                                                                id="bien_servicio"
                                                                className={((this.verificarError("error_bien_servicio")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={this.cambioValor}>
                                                                <option value="">Seleccione el Bien - Servicio</option>
                                                                <option value="1">BIENES SERVICIO 1</option>
                                                                <option value="2">BIENES SERVICIO 2</option>
                                                                <option value="3">BIENES SERVICIO 3</option>
                                                                <option value="4">BIENES SERVICIO 4</option>
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione el Bien - Servicio</small>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                        <div className="col-md-3">
                                                            <label htmlFor="usuario">Usuario</label>
                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="usuario" id="usuario" value={usuario}
                                                                className={((this.verificarError("error_usuario")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Usuario" aria-describedby="helpId"
                                                                onChange={this.cambioValor} max="37" />
                                                            <small id="helpId" className="invalid-feedback">Digite el Usuario</small>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="contrasena">Contraseña</label>
                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="contrasena" id="contrasena" value={contrasena}
                                                                className={((this.verificarError("error_contrasena")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Contraseña" aria-describedby="helpId"
                                                                onChange={this.cambioValor} max="37" />
                                                            <small id="helpId" className="invalid-feedback">Digite la Contraseña</small>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="correo_electronico">Correo Electrónico</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico" id="correo_electronico" value={correo_electronico}
                                                                    className={((this.verificarError("error_correo_electronico")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico</small>
                                                        </div>
                                                    </div>
                                                    <hr style={{ marginBottom: 0 }} />
                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                        <div className="col-md-3">
                                                            <label htmlFor="titularidad">Titularidad</label>
                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="titularidad" id="titularidad" value={titularidad}
                                                                className={((this.verificarError("error_titularidad")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Titularidad" aria-describedby="helpId"
                                                                onChange={this.cambioValor} max="37" />
                                                            <small id="helpId" className="invalid-feedback">Digite la Titularidad</small>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="compromiso">Compromiso</label>
                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="compromiso" id="compromiso" value={compromiso}
                                                                className={((this.verificarError("error_compromiso")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Compromiso" aria-describedby="helpId"
                                                                onChange={this.cambioValor} max="37" />
                                                            <small id="helpId" className="invalid-feedback">Digite el Compromiso</small>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 0 }} className='row g-3'>
                                                        <div className="col-md-12">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" value="" id="terminos_condiciones" />
                                                                <label class="form-check-label" for="terminos_condiciones">
                                                                    Términos y Condiciones
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="nav-datos_generales" role="tabpanel" aria-labelledby="nav-datos_generales-tab">
                                            <div style={{ marginTop: 15 }} className="row">
                                                <div className="col-xl-12">
                                                    <div className='row g-3'>
                                                        <div className="col-md-3">
                                                            <label htmlFor="numero_identificacion">Número Identificación</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="numero_identificacion" id="numero_identificacion" value={numero_identificacion}
                                                                    className={((this.verificarError("error_numero_identificacion")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Identificación" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Digite el Número Identificación</small>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label htmlFor="digito_verificacion">DV</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="digito_verificacion" id="digito_verificacion" value={digito_verificacion}
                                                                    className={((this.verificarError("error_digito_verificacion")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="DV" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Digite el DV</small>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <label htmlFor="tipo_persona">Tipo Persona</label>
                                                            <select name="tipo_persona"
                                                                id="tipo_persona"
                                                                className={((this.verificarError("error_tipo_persona")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={this.cambioValor}>
                                                                <option value="">Seleccione el Tipo Persona</option>
                                                                <option value="1">PERSONA NATURAL</option>
                                                                <option value="2">PERSONA JURÍDICA</option>
                                                                <option value="3">EXTERIOR</option>
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Persona</small>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 0}} className='row g-3'>
                                                        <div className='col-md-3'>
                                                            <label htmlFor="tipo_documento">Tipo Documento</label>
                                                            <select name="tipo_documento"
                                                                id="tipo_documento"
                                                                className={((this.verificarError("error_tipo_documento")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={this.cambioValor}>
                                                                <option value="">Seleccione el Tipo Documento</option>
                                                                <option value="1">CÉDULA CIUDADANIA</option>
                                                                <option value="2">NIT</option>
                                                                <option value="3">CÉDULA EXTRANJERÍA</option>
                                                                <option value="4">PASAPORTE</option>
                                                                <option value="5">TAX ID.</option>
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Documento</small>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="numero_identificacion_2">Número Identificación</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="numero_identificacion_2" id="numero_identificacion_2" value={numero_identificacion_2}
                                                                    className={((this.verificarError("error_numero_identificacion_2")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Número Identificación" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Digite el Número Identificación</small>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label htmlFor="fecha_expedicion">Fecha Expedición</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="date" name="fecha_expedicion" id="fecha_expedicion" value={fecha_expedicion}
                                                                    className={((this.verificarError("error_fecha_expedicion")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Fecha Expedición" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Seleccione la Fecha Expedición</small>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                    <div className='col-md-3'>
                                                            <label htmlFor="id_departamento_expedicion">Departamento</label>
                                                            <select name="id_departamento_expedicion"
                                                                id="id_departamento_expedicion"
                                                                className={((this.verificarError("error_id_departamento_expedicion")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={e => {
                                                                    this.cargarMunicipios(e, "expedicion");
                                                                    this.cambioValor(e)
                                                                }}>
                                                                <option value="">Seleccione el Departamento</option>
                                                                {
                                                                    departamentos.map((itemDepartamentos) => (
                                                                        <option style={{ textTransform: 'uppercase' }}  key={itemDepartamentos.departamento} value={itemDepartamentos.departamento}>{itemDepartamentos.departamento}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione el Departamento</small>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <label htmlFor="id_municipio_expedicion">Municipio</label>
                                                            <select name="id_municipio_expedicion"
                                                                id="id_municipio_expedicion"
                                                                className={((this.verificarError("error_id_municipio_expedicion")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={(e) => {
                                                                    this.cambioValor(e);
                                                                    this.cargarValores(e);
                                                                }}>
                                                                <option value="">Seleccione el Municipio</option>
                                                                {
                                                                    municipios.map((itemMunicipios) => (
                                                                        <option style={{ textTransform: 'uppercase' }}  key={itemMunicipios.ID_TABLA} value={itemMunicipios.ID_MUNICIPIO}>{itemMunicipios.NOMBRE}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione el Municipio</small>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                        <div className="col-md-3">
                                                            <label htmlFor="primer_apellido">Primer Apellido</label>
                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="primer_apellido" id="primer_apellido" value={primer_apellido}
                                                                className={((this.verificarError("error_primer_apellido")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Primer Apellido" aria-describedby="helpId"
                                                                onChange={this.cambioValor} max="37" />
                                                            <small id="helpId" className="invalid-feedback">Digite el Primer Apellido</small>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="segundo_apellido">Segundo Apellido</label>
                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="segundo_apellido" id="segundo_apellido" value={segundo_apellido}
                                                                className={((this.verificarError("error_segundo_apellido")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Segundo Apellido" aria-describedby="helpId"
                                                                onChange={this.cambioValor} max="37" />
                                                            <small id="helpId" className="invalid-feedback">Digite el Segundo Apellido</small>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="primer_nombre">Primer Nombre</label>
                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="primer_nombre" id="primer_nombre" value={primer_nombre}
                                                                className={((this.verificarError("error_primer_nombre")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Primer Nombre" aria-describedby="helpId"
                                                                onChange={this.cambioValor} max="37" />
                                                            <small id="helpId" className="invalid-feedback">Digite el Primer Nombre</small>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="segundo_nombre">Segundo Nombre</label>
                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="segundo_nombre" id="segundo_nombre" value={segundo_nombre}
                                                                className={((this.verificarError("error_segundo_nombre")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Segundo Nombre" aria-describedby="helpId"
                                                                onChange={this.cambioValor} max="37" />
                                                            <small id="helpId" className="invalid-feedback">Digite el Segundo Nombre</small>
                                                        </div>
                                                    </div>
                                                    <hr style={{ marginBottom: 0 }} />
                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                        <div className="col-md-6">
                                                            <label htmlFor="razon_social">Razón Social</label>
                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="razon_social" id="razon_social" value={razon_social}
                                                                className={((this.verificarError("error_razon_social")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Razón Social" aria-describedby="helpId"
                                                                onChange={this.cambioValor} max="37" />
                                                            <small id="helpId" className="invalid-feedback">Digite la Razón Social</small>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label htmlFor="tipo_empresa">Tipo Empresa</label>
                                                            <select name="tipo_empresa"
                                                                id="tipo_empresa"
                                                                className={((this.verificarError("error_tipo_empresa")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={this.cambioValor}>
                                                                <option value="">Seleccione el Tipo Empresa</option>
                                                                <option value="1">PRIVADA</option>
                                                                <option value="2">MIXTA</option>
                                                                <option value="3">PÚBLICA</option>
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Empresa</small>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label htmlFor="tipo_sociedad">Tipo Sociedad</label>
                                                            <select name="tipo_sociedad"
                                                                id="tipo_sociedad"
                                                                className={((this.verificarError("error_tipo_sociedad")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={this.cambioValor}>
                                                                <option value="">Seleccione el Tipo Sociedad</option>
                                                                <option value="1">SOCIEDAD POR ACCIONES SIMPLIFICADAS (S.A.S.)</option>
                                                                <option value="2">SOCIEDAD ANÓNIMA (S.A.)</option>
                                                                <option value="3">SOCIEDAD LIMITADA (LTDA.)</option>
                                                                <option value="4">ENTIDAD SIN ÁNIMO DE LUCRO (E.S.A.L.)</option>
                                                                <option value="5">SUCURSAL DE SOCIEDAD EXTRANJERA (S.S.E.)</option>
                                                                <option value="6">SOCIEDAD COLECTIVA (S.C.)</option>
                                                                <option value="7">SOCIEDAD EN COMANDITA POR ACCIONES (S.C.A)</option>
                                                                <option value="8">SOCIEDAD EN COMANDITA SIMPLE (S.C.S.)</option>
                                                                <option value="9">EMPRESA UNIPERSONAL</option>
                                                                <option value="10">SOCIEDAD ASOCIATIVA DE TRABAJO (S.A.T.)</option>
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione el Tipo Sociedad</small>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                        <div className="col-md-4">
                                                            <label htmlFor="nombre_comercial">Nombre Comercial</label>
                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="nombre_comercial" id="nombre_comercial" value={nombre_comercial}
                                                                className={((this.verificarError("error_nombre_comercial")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Nombre Comercial" aria-describedby="helpId"
                                                                onChange={this.cambioValor} max="37" />
                                                            <small id="helpId" className="invalid-feedback">Digite el Nombre Comercial</small>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label htmlFor="siglas">Siglas</label>
                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="siglas" id="siglas" value={siglas}
                                                                className={((this.verificarError("error_siglas")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Siglas" aria-describedby="helpId"
                                                                onChange={this.cambioValor} max="37" />
                                                            <small id="helpId" className="invalid-feedback">Digite las Siglas</small>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <label htmlFor="id_departamento_funcionamiento">Departamento</label>
                                                            <select name="id_departamento_funcionamiento"
                                                                id="id_departamento_funcionamiento"
                                                                className={((this.verificarError("error_id_departamento_funcionamiento")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={e => {
                                                                    this.cargarMunicipios(e, "funcionamiento");
                                                                    this.cambioValor(e)
                                                                }}>
                                                                <option value="">Seleccione el Departamento</option>
                                                                {
                                                                    departamentos_funcionamiento.map((itemDepartamentosFuncionamiento) => (
                                                                        <option style={{ textTransform: 'uppercase' }} key={itemDepartamentosFuncionamiento.departamento} value={itemDepartamentosFuncionamiento.departamento}>{itemDepartamentosFuncionamiento.departamento}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione el Departamento</small>
                                                        </div>
                                                        <div className='col-md-3'>
                                                            <label htmlFor="id_municipio_funcionamiento">Municipio</label>
                                                            <select name="id_municipio_funcionamiento"
                                                                id="id_municipio_funcionamiento"
                                                                className={((this.verificarError("error_id_municipio_funcionamiento")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={(e) => {
                                                                    this.cambioValor(e);
                                                                    this.cargarValores(e);
                                                                }}>
                                                                <option value="">Seleccione el Municipio</option>
                                                                {
                                                                    municipios_funcionamiento.map((itemMunicipiosFuncionamiento) => (
                                                                        <option style={{ textTransform: 'uppercase' }}  key={itemMunicipiosFuncionamiento.ID_TABLA} value={itemMunicipiosFuncionamiento.ID_MUNICIPIO}>{itemMunicipiosFuncionamiento.NOMBRE}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione el Municipio</small>
                                                        </div>
                                                    </div>
                                                    <hr style={{ marginBottom: 0 }} />
                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                        <div className="col-md-2">
                                                            <label htmlFor="id_direccion_empresa_1">Dirección</label>
                                                            <select name="id_direccion_empresa_1"
                                                                id="id_direccion_empresa_1"
                                                                className={((this.verificarError("error_id_direccion_empresa_1")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={this.cambioValor}>
                                                                <option value="">Seleccione la Dirección</option>
                                                                <option value="1">CALLE</option>
                                                                <option value="2">CARRERA</option>
                                                                <option value="3">AVENIDA</option>
                                                                <option value="4">MANZANA</option>
                                                                <option value="5">DIAGONAL</option>
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione la Dirección</small>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label htmlFor="direccion_empresa_1">No.</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="direccion_empresa_1" id="direccion_empresa_1" value={direccion_empresa_1}
                                                                    className={((this.verificarError("error_direccion_empresa_1")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="No." aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Digite el No.</small>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label htmlFor="complemento_direccion_empresa_1">Letra</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faFont} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="complemento_direccion_empresa_1" id="complemento_direccion_empresa_1" value={complemento_direccion_empresa_1}
                                                                    className={((this.verificarError("error_complemento_direccion_empresa_1")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Letra" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Digite la Letra</small>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <label htmlFor="id_direccion_empresa_2">Dirección</label>
                                                            <select name="id_direccion_empresa_2"
                                                                id="id_direccion_empresa_2"
                                                                className={((this.verificarError("error_id_direccion_empresa_2")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                onChange={this.cambioValor}>
                                                                <option value="">Seleccione la Dirección</option>
                                                                <option value="1">CALLE</option>
                                                                <option value="2">CARRERA</option>
                                                                <option value="3">AVENIDA</option>
                                                                <option value="4">MANZANA</option>
                                                                <option value="5">DIAGONAL</option>
                                                            </select>
                                                            <small id="helpId" className="invalid-feedback">Seleccione la Dirección</small>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label htmlFor="direccion_empresa_2">No.</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="direccion_empresa_2" id="direccion_empresa_2" value={direccion_empresa_2}
                                                                    className={((this.verificarError("error_direccion_empresa_2")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="No." aria-describedby="helpId"
                                                                    onChange={this.cambioValor} />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Digite el No.</small>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label htmlFor="complemento_direccion_empresa_2">Letra</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faFont} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="complemento_direccion_empresa_2" id="complemento_direccion_empresa_2" value={complemento_direccion_empresa_2}
                                                                    className={((this.verificarError("error_complemento_direccion_empresa_2")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Letra" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Digite la Letra</small>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label htmlFor="direccion_empresa_3">No.</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="direccion_empresa_3" id="direccion_empresa_3" value={direccion_empresa_3}
                                                                    className={((this.verificarError("error_direccion_empresa_3")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="No." aria-describedby="helpId"
                                                                    onChange={this.cambioValor} />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Digite el No.</small>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="complemento_direccion_empresa">Complemento</label>
                                                            <input style={{ textTransform: 'uppercase' }} type="text" name="complemento_direccion_empresa" id="complemento_direccion_empresa" value={complemento_direccion_empresa}
                                                                className={((this.verificarError("error_complemento_direccion_empresa")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Complemento" aria-describedby="helpId"
                                                                onChange={this.cambioValor} />
                                                            <small id="helpId" className="invalid-feedback">Digite el Complemento</small>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                        <div className="col-md-3">
                                                            <label htmlFor="correo_electronico_empresa">Correo Electrónico</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faAt} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="correo_electronico_empresa" id="correo_electronico_empresa" value={correo_electronico_empresa}
                                                                    className={((this.verificarError("error_correo_electronico_empresa")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Correo Electrónico" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Digite el Correo Electrónico</small>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="telefono_1">Teléfono 1</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="telefono_1" id="telefono_1" value={telefono_1}
                                                                    className={((this.verificarError("error_telefono_1")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono 1" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Digite el Teléfono 1</small>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="telefono_2">Teléfono 2</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faHashtag} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="telefono_2" id="telefono_2" value={telefono_2}
                                                                    className={((this.verificarError("error_telefono_2")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Teléfono 2" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Digite el Teléfono 1</small>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label htmlFor="pagina_web">Página Web</label>
                                                            <div className="input-group input-group-sm">
                                                                <span className="input-group-text" id="inputGroupPrepend"><FontAwesomeIcon icon={faGlobe} /></span>
                                                                <input style={{ textTransform: 'uppercase' }} type="text" name="pagina_web" id="pagina_web" value={pagina_web}
                                                                    className={((this.verificarError("error_pagina_web")) ? "is-invalid" : "border border-info") + " form-control form-control-sm"} placeholder="Página Web" aria-describedby="helpId"
                                                                    onChange={this.cambioValor} max="37" />
                                                            </div>
                                                            <small id="helpId" className="invalid-feedback">Digite la Página Web</small>
                                                        </div>
                                                    </div>
                                                    <hr style={{ marginBottom: 0 }} />
                                                    <div style={{ marginTop: 0 }} className="row g-3">
                                                        <div className='col-md-6'>
                                                            <label htmlFor="files_rut">Datos Adjuntos (RUT + Cédula)</label>
                                                            <div className='input-group input-group-sm mb-3'>
                                                                <label className="input-group-text label_files" htmlFor="files_rut"><FontAwesomeIcon icon={faFolderOpen} />&nbsp;&nbsp;Seleccionar Archivo(s) </label>
                                                                <input
                                                                    className={((this.verificarError("error_files_rut")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    type="file" id="files_rut" multiple accept="application/pdf"
                                                                    onChange={(e) => {
                                                                        this.cambioValorFiles(e);
                                                                        this.verifyFileSize(e);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <label htmlFor="files_camara">Datos Adjuntos (Cámara)</label>
                                                            <div className='input-group input-group-sm mb-3'>
                                                                <label className="input-group-text label_files" htmlFor="files_camara"><FontAwesomeIcon icon={faFolderOpen} />&nbsp;&nbsp;Seleccionar Archivo(s) </label>
                                                                <input
                                                                    className={((this.verificarError("error_files_camara")) ? "is-invalid" : "border border-info") + " form-select form-select-sm"}
                                                                    type="file" id="files_camara" multiple accept="application/pdf"
                                                                    onChange={(e) => {
                                                                        this.cambioValorFiles(e);
                                                                        this.verifyFileSize(e);
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-xl-12 text-center'>
                                            <div className="btn-group" role="group" aria-label="">
                                                <button type="submit" className="btn btn-success btn-sm"><FontAwesomeIcon icon={faPaperPlane} />&nbsp;&nbsp;Enviar</button>&nbsp;&nbsp;
                                                <Link className="btn btn-danger btn-sm" to={"/MasterTerceros"}><FontAwesomeIcon icon={faTimes} />&nbsp;&nbsp;Cancelar</Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CrearTerceros;