import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Header from './layout/Header';
import Menu from './layout/Menu';
import Footer from './layout/Footer';

import Usuarios from '../src/components/usuarios/Index';
import CrearUsuario from '../src/components/usuarios/Crear';
import EditarUsuario from '../src/components/usuarios/Editar';
import CrearNuevoUsuario from '../src/components/usuarios/CrearNuevoUsuario';

import Roles from '../src/components/roles/Index';
import CrearRol from '../src/components/roles/Crear';
import EditarRol from '../src/components/roles/Editar';

import Terceros from './components/form_terceros/Index';
import CrearTercero from './components/form_terceros/CrearTerceros';
import EditarTercero from './components/form_terceros/EditarTerceros';

// import ProveedoresNacionalesJuridicos from './components/form_prov_nacional_juridico/Index';
// import CrearProveedorNacionalJuridico from './components/form_prov_nacional_juridico/CrearProveedoresNacionalesJuridicos';
// // import EditarProveedorNacionalJuridico from './components/form_prov_nacional_juridico/EditarProveedoresNacionalesJuridicos';

// import ProveedoresNacionalesNaturales from './components/form_prov_nacional_natural/Index';
// import CrearProveedorNacionalNatural from './components/form_prov_nacional_natural/CrearProveedoresNacionalesNaturales';
// // import EditarProveedorNacionalNatural from './components/form_prov_nacional_juridico/EditarProveedoresNacionalesNaturales';

// import ProveedoresAccionistas from './components/form_prov_accionista/Index';
// import CrearProveedorAccionista from './components/form_prov_accionista/CrearProveedoresAccionistas';
// // import EditarProveedorAccionista from './components/form_prov_accionista/EditarProveedoresAccionistas';


function App() {
	const ux = localStorage.getItem("email");
	return (

		<Router>
			{ux != null ? <Header /> : ''}
			{ux != null ? <Menu /> : ''}
			{ux != null ?
				<Route exact path="/" component={Terceros}></Route>
				:
				<Route exact path="/" component={Login}></Route>
			}
			<Route exact path="/MasterTerceros" component={ux != null ? Terceros : Login}></Route>
			<Route exact path="/AddTerceros" component={ux != null ? CrearTercero : Login}></Route>
			<Route exact path="/UpTerceros/:id" component={ux != null ? EditarTercero : Login}></Route>

			{/* <Route exact path="/MasterProveedoresNacionalesJuridicos" component={ux != null ? ProveedoresNacionalesJuridicos : Login}></Route>
			<Route exact path="/AddProveedoresNacionalesJuridicos" component={ux != null ? CrearProveedorNacionalJuridico : Login}></Route>
			<Route exact path="/UpProveedoresNacionalesJuridicos/:id" component={ux != null ? EditarProveedorNacionalJuridico : Login}></Route>

			<Route exact path="/MasterProveedoresNacionalesNaturales" component={ux != null ? ProveedoresNacionalesNaturales : Login}></Route>
			<Route exact path="/AddProveedoresNacionalesNaturales" component={ux != null ? CrearProveedorNacionalNatural : Login}></Route>
			<Route exact path="/UpProveedoresNacionalesNaturales/:id" component={ux != null ? EditarProveedorNacionalNatural : Login}></Route>

			<Route exact path="/MasterProveedoresAccionistas" component={ux != null ? ProveedoresAccionistas : Login}></Route>
			<Route exact path="/AddProveedoresAccionistas" component={ux != null ? CrearProveedorAccionista : Login}></Route>
			<Route exact path="/UpProveedoresAccionistas/:id" component={ux != null ? EditarProveedorAccionista : Login}></Route> */}

			<Route exact path="/MasterUsuarios" component={ux != null ? Usuarios : Login}></Route>
			<Route exact path="/AddUsuarios" component={ux != null ? CrearUsuario : Login}></Route>
			<Route exact path="/UpUsuarios/:id" component={ux != null ? EditarUsuario : Login}></Route>
			<Route exact path="/AddNewUsuarios" component={CrearNuevoUsuario}></Route>

			<Route exact path="/MasterRoles" component={ux != null ? Roles : Login}></Route>
			<Route exact path="/AddRoles" component={ux != null ? CrearRol : Login}></Route>
			<Route exact path="/UpRoles/:id" component={ux != null ? EditarRol : Login}></Route>

			{ux != null ? <Footer /> : ''}
		</Router>
	);
}

export default App;
